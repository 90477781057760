import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChannelFeed } from '../../../components';
import { AuthContext } from '../../../context/auth/auth-context';
import { ServiceContext } from '../../../context/services/service-context';
import { VIDEOWALL } from '../../../layout/config/videowall.config';

import EstorilOpenAppLogo from "../../../assets/images/videowall/estorilOpen/InnowaveLogo.png";
import { INSTANCE } from '../../../layout/config/instance.config';
import Styles from './style';

const useStyles = makeStyles(Styles);

const WIDTH = VIDEOWALL.resolution.width;
const HEIGHT = VIDEOWALL.resolution.height;
const BACKGROUND = VIDEOWALL.background;
const OVERLAYIMG = VIDEOWALL.overlayImg;
const LOGOS = VIDEOWALL.logos
const AUDIO = VIDEOWALL.audio;
const QRCODE = VIDEOWALL.qrcode;
const CHANNEL_WIDTH = VIDEOWALL.channels.width;
const CHANNEL_HEIGHT = VIDEOWALL.channels.height;
const MAX_FEED_CHANNELS = VIDEOWALL.channels.total;
const REQUEST_INTERVAL = VIDEOWALL.request_interval;
const TRANSFORM = VIDEOWALL.channels.positions;
const DEBUG = VIDEOWALL.debug;
const DISPLAY_CENTER = VIDEOWALL.displayCenter;

const ViewerView = () => {

    const classes = useStyles();
    const channelRefs = useRef([]);
    const audioRef = useRef(null);
    const interval = useRef(null);
    const { VideoStream } = useContext(ServiceContext);
    const { signin, currentUserCredentials, currentUserSession } = useContext(AuthContext);
    const [credentials, setCredentials] = useState(null);
    const [status, setStatus] = useState(null);
    const [positions, setPositions] = useState([]);
    const [videowallStatus, setVideowallStatus] = useState(false);
    const [eventDisplayName, setEventDisplayName] = useState('');
    const [displayPlaceholder, setDisplayPlaceholder] = useState(true)


    const [requestFeeds, setRequestFeeds] = useState([]);
    const [channelFeeds, setChannelFeeds] = useState([]);


    useEffect(() => {
        generatePositionsArray();
        authAnonymousUser();
        getEventDisplayName();
    }, [])


    useEffect(() => {
        const checkStatus = setInterval(() => {
            getEventStatus();
        }, REQUEST_INTERVAL);
        return () => clearInterval(checkStatus);
    }, []);


    useEffect(() => {
        if (credentials && status) {
            interval.current = setInterval(() => getAvailableChannels(positions), REQUEST_INTERVAL);
        }
        return () => clearInterval(interval.current);
    }, [credentials, status]);


    useEffect(() => {
        filterChanges(requestFeeds, channelFeeds)
    }, [requestFeeds])


    useEffect(() => {
        if (!status) {
            serviceStopedHandler();
        }
    }, [status])


    const authAnonymousUser = async () => {
        try {
            let haveSession = await currentUserSession();
            let haveCredentials = await currentUserCredentials();
            if (!haveSession) {
                let { data } = await VideoStream.getAnonymousUser();
                await signin(data.getAnonymousUser.username, data.getAnonymousUser.password);
                haveCredentials = await currentUserCredentials();
            }
            setCredentials(haveCredentials);
        } catch (error) {
            throw error
        }
    }

    const getEventStatus = async () => {
        const status = await VideoStream.getEventStatus();
        setStatus(status === "ACTIVE" ? true : false);
        if (DEBUG) console.log("Event Status:", status);
        if (DEBUG) console.log("Videowall Status:", videowallStatus);
    }

    const filterChannels = (channels) => {
        const channelIsOn = channels.filter(item => item.name !== null);
        if(channelIsOn.length > 0){
            setDisplayPlaceholder(false)
       }else{
            setDisplayPlaceholder(true)
       }
    }


    const getAvailableChannels = async (positionList) => {
        try {
            let channels = await VideoStream.getPositionContents(positionList, true);
            if (channels) {
                setRequestFeeds(channels);
                filterChannels(channels)
                if (!videowallStatus) {
                    setVideowallStatus(true);
                }
            }
            if (DEBUG) console.log("Get Available Channels:", channels);

        } catch (error) {
            throw error
        }
    }

    const generatePositionsArray = () => {

        let array = []
        for (let i = 0; i < MAX_FEED_CHANNELS; i++) {
            array.push(`position_${i}`);
        }
        setPositions(array);
    }


    const filterChanges = (newArray, prevArray) => {
        const array = newArray.filter(itemPrev => {
            return !prevArray.some(itemNew => {
                return (itemPrev.name === itemNew.name && itemPrev.startDisplayTimestamp === itemNew.startDisplayTimestamp)
            })
        });
        if (array.length > 0) {
            updateChannelFeed(array);
        }
        setChannelFeeds(newArray);
        if (DEBUG) {
            console.log("PREV: ", prevArray);
            console.log("NEW: ", newArray);
            console.log("FILTERED: ", array);
            console.log("----------------------------------------------------------------------------");
        }
    }

    const updateChannelFeed = async (updateList) => {
        updateList.forEach(element => {
            let position = channelRefs.current.find(item => item.position === element.position);
            if (position && element.name !== null) {
                position.reset(element.position, element.name, credentials, "videowall");
            } else {
                position.stop();
            }
        });
        if (DEBUG) {
            console.log("UPDATE LIST WITH: ", updateList);
        }
    }

    const serviceStopedHandler = async () => {
        if (channelFeeds.length > 0) {
            channelRefs.current.forEach(async (element) => {
                await element.stop();
            });
            setChannelFeeds([]);
            if (DEBUG) console.log("Channel Feeds: CLEARED");
        }
        setVideowallStatus(false);
        // audioRef.current.pause();
    }

    // const playAudio = () => {
    //     if (videowallStatus) {
    //         audioRef.current.play();
    //     }
    // }

    const removeChannelFeed = async (position, channel) => {
        await VideoStream.removeContentFromGrid(position, channel);
    }

    const getEventDisplayName = async() => {
        const result = await VideoStream.getEventDisplayName();
        setEventDisplayName(result)
    }

    return (
        <div className={classes.root} style={{ alignItems: DISPLAY_CENTER ? 'center' : 'unset', justifyContent: DISPLAY_CENTER ? 'center' : 'unset', backgroundColor: BACKGROUND.color, }}>
            <div className={classes.body} style={{ width: `${WIDTH}`, height: `${HEIGHT}` }}>
                <div className={classes.backgroundCover} style={{ width: "100%" }}>
                    {
                        BACKGROUND.status && (BACKGROUND.type === 'VIDEO') ? <video src={BACKGROUND.src} autoPlay={true} muted loop /> : null
                    }
                    {
                        BACKGROUND.status && (BACKGROUND.type === 'IMAGE') ? <img src={BACKGROUND.src} style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%', height: '100%', width: '100%', margin: "auto" }} alt="" /> : null
                    }
                    {
                        // AUDIO.status ? <audio ref={audioRef} src={AUDIO.src} style={{ display: 'none' }} loop controls autoPlay /> : null
                    }
                </div>
                <div className={classes.content}>
                        {
                            LOGOS.top_middle.status ?
                                <div className={classes.textMiddle}>{eventDisplayName}</div>
                                : null
                        }
                    <div className={classes.header}>
                        {
                            LOGOS.top_left.status ?
                                <img src={INSTANCE.urlAssets+'logo?'+Date.now()} alt="" className={classes.imgLeft} />
                                : null
                        }
                        {/* {
                            LOGOS.middle_bottom.status ?
                                <div onClick={playAudio}><img src={LOGOS.middle_bottom.src} alt="" /></div>
                                : null
                        } */}
                        {
                            LOGOS.top_right.status ?
                                <img src={EstorilOpenAppLogo} alt="" className={classes.imgRight} />
                                : null
                        }
                    </div>
                    <div className={classes.feedsContainer}>
                        {displayPlaceholder ?
                            <div className="overlay-img">
                                <img src={VIDEOWALL.overlayImg.src} alt="" />
                            </div>
                        : null}
                            {
                                positions.map((prop, index) =>
                                    <ChannelFeed
                                        channelRef={(value) => channelRefs.current.push(value)}
                                        key={index}
                                        id={prop}
                                        width={CHANNEL_WIDTH}
                                        height={CHANNEL_HEIGHT}
                                        transform={TRANSFORM[index]}
                                        debugChannel={DEBUG}
                                        debug
                                        origin = "videowall"
                                        remove={removeChannelFeed}
                                    />
                                )
                            }
                            {
                                QRCODE.status && !displayPlaceholder ?
                                    <div className={classes.qrcodeBox}
                                        style={{
                                            // width: `${QRCODE.width}px`,
                                            // height: `${QRCODE.height}px`,
                                            // position: 'absolute',
                                            // top: QRCODE.position.top,
                                            // left: QRCODE.position.left
                                            backgroundColor: 'white',
                                        }}>
                                        <img src={INSTANCE.urlAssets+'qrCode?'+Date.now()} alt="" />
                                        {
                                            !videowallStatus ? <span className={classes.connectionPoint} /> : null
                                        }
                                    </div>
                                    : null
                                }
                                {!displayPlaceholder && <div className="qrcode-text">Faz scan para acederes a esta experiência!</div>}

                    </div>
                    {/* <div className={classes.textFooter}>
                        <img src={INSTANCE.urlAssets+'logoFooter?'+Date.now()} alt="" />

                    </div> */}
                </div>


                {/*    OVERLAY  */}
                {/* < className={classes.overlay} style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}/> */}
                {/* ------------- */}
            </div>
        </div>
    );
}

export default ViewerView;
