import React, { useRef, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Styles from './style';
import { INSTANCE } from '../../../layout/config/instance.config';
import { useNotifications } from '../../Shared/NotificationSnackbar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(Styles);

const ImageUpdate = ({onChangeImage, width, height='auto', fileName, disabled, label, aspectRatio, aspectRatioText}) => {
    const classes = useStyles();
    const imageRef = useRef();
    const { triggerNotification } = useNotifications();
    const { t } = useTranslation()

    const imagePreview = async (event) => {
        let file = event.target.files[0]
        file.fileName = fileName
        const urlBlobImage = URL.createObjectURL(file);
        let imgCheck = new Image();      
        imgCheck.src = urlBlobImage;
        imgCheck.onload = () => {
            console.log(aspectRatio, fileName)
          if (aspectRatio-1 < (imgCheck.width/imgCheck.height) && (imgCheck.width/imgCheck.height)  < aspectRatio+1){
            onChangeImage(file, true)
            imageRef.current.src = urlBlobImage
          }else{
            triggerNotification("error", "notifications-image-Error-message", "notifications-image-Error-title")
            onChangeImage(false, false)
          }
        }
    }

  return (
    <div className={classes.contentInput} style={{width: width, height: height}}>
        <label htmlFor={fileName} className={classes.labelFileUpload}>
            <div className={classes.labelTitle}>{label}</div>
            <div className={classes.imageContent}>
              <img src={INSTANCE.urlAssets+fileName+'?'+Date.now()}  alt={label} ref={imageRef} />
              <span>{disabled ? t('image-text-to-edit'): t('image-text-editabled')}</span>
            </div>
            <input type="file" id={fileName} className={classes.inputHidden} accept="image/png, image/jpeg" onChange={(e) => imagePreview(e)} disabled={disabled}/>
        </label>
        <span className={classes.aspectRatio}>{aspectRatioText}</span>
    </div>
  )
}

export default ImageUpdate