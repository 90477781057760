
import { useNotifications } from '../../../components/Shared/NotificationSnackbar';
import { CreateCustomEventNotification, DeleteCustomEventNotification, ListEventNotifications, UpdateCustomEventNotification } from '../../../service/api/third-party';

export const ThirdPartyService = () => {

    const { triggerNotification } = useNotifications();

    const listEventNotifications = async () => {
        try {
            const result = await ListEventNotifications()
            return result.data.listEventNotifications
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const createCustomEventNotification = async (data) => {
        try {
            const result = await CreateCustomEventNotification(data)
            return result.data.createCustomEventNotification
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const updateCustomEventNotification = async (data) => {
        try {
            const result = await UpdateCustomEventNotification(data);
            return result.data.updateCustomEventNotification
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }
    
    const deleteCustomEventNotification = async (data) => {
        try {
            const result = await DeleteCustomEventNotification(data);
            return result.data.deleteCustomEventNotification
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    return { listEventNotifications, createCustomEventNotification, updateCustomEventNotification, deleteCustomEventNotification }
}