import AWS from 'aws-sdk';
import { Auth } from "aws-amplify";

const DEBUG = false;

async function RenewSession() {
    await Auth.currentSession();
    let credentials = await Auth.currentCredentials();
    return credentials;
}

export async function startViewer(remoteView, formValues, onStatsReport, onRemoteDataMessage, remove, position, oldViewer = null, type) {

    let viewer = {};
    viewer.remoteView = remoteView;

    //renew credentials if necessary (every request)
    let renewedCredentials = await RenewSession();

    /**
    * Create KVS client
    */
    const kinesisVideoClient = new AWS.KinesisVideo({
        region: formValues.region,
        accessKeyId: renewedCredentials.accessKeyId,
        secretAccessKey: renewedCredentials.secretAccessKey,
        sessionToken: renewedCredentials.sessionToken,
        endpoint: formValues.endpoint,
        correctClockSkew: true,
    });


    /**
     *  Get signaling channel ARN
     */
    const describeSignalingChannelResponse = await kinesisVideoClient
        .describeSignalingChannel({
            ChannelName: formValues.channelName,
        })
        .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    if (DEBUG) console.log('[VIEWER] Channel ARN: ', channelARN);


    /**
     * Get signaling channel endpoints
     */
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
        .getSignalingChannelEndpoint({
            ChannelARN: channelARN,
            SingleMasterChannelEndpointConfiguration: {
                Protocols: ['WSS', 'HTTPS'],
                Role: window.KVSWebRTC.Role.VIEWER,
            },
        })
        .promise();
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
    }, {});
    if (DEBUG) console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
        region: formValues.region,
        accessKeyId: renewedCredentials.accessKeyId,
        secretAccessKey: renewedCredentials.secretAccessKey,
	    sessionToken: renewedCredentials.sessionToken,
        endpoint: endpointsByProtocol.HTTPS,
        correctClockSkew: true,
    });


    /**
     * Get ICE server configuration
     */
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
        .getIceServerConfig({
            ChannelARN: channelARN,
        })
        .promise();
    const iceServers = [];
    if (!formValues.natTraversalDisabled && !formValues.forceTURN) {
        iceServers.push({ urls: `stun:stun.kinesisvideo.${formValues.region}.amazonaws.com:443` });
    }
    if (!formValues.natTraversalDisabled) {
        getIceServerConfigResponse.IceServerList.forEach(iceServer =>
            iceServers.push({
                urls: iceServer.Uris,
                username: iceServer.Username,
                credential: iceServer.Password,
            }),
        );
    }
    if (DEBUG) console.log('[VIEWER] ICE servers: ', iceServers);


    /**
     * Create Signaling Client
     */
    viewer.signalingClient = new window.KVSWebRTC.SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        clientId: formValues.clientId,
        role: window.KVSWebRTC.Role.VIEWER,
        region: formValues.region,
        credentials: {
            accessKeyId: renewedCredentials.accessKeyId,
	        secretAccessKey: renewedCredentials.secretAccessKey,
            sessionToken: renewedCredentials.sessionToken,
        },
        systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const resolution = formValues.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = { video: formValues.sendVideo ? resolution : false, audio: formValues.sendAudio };
    const configuration = { iceServers, iceTransportPolicy: formValues.forceTURN ? 'relay' : 'all' };
    viewer.peerConnection = new RTCPeerConnection(configuration);
    if (formValues.openDataChannel) {
        viewer.dataChannel = viewer.peerConnection.createDataChannel('kvsDataChannel');
        viewer.peerConnection.ondatachannel = event => {
            event.channel.onmessage = onRemoteDataMessage;
        };
    }


    /**
     * Poll for connection stats
     */
    viewer.peerConnectionStatsInterval = setInterval(() => viewer.peerConnection.getStats().then(onStatsReport), 1000);

    viewer.signalingClient.on('open', async () => {
        // Create an SDP offer to send to the master
        if (DEBUG) console.log('[VIEWER] Creating SDP offer');
        await viewer.peerConnection.setLocalDescription(
            await viewer.peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
            }),
        );
        // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
        if (formValues.useTrickleICE) {
            if (DEBUG) console.log('[VIEWER] Sending SDP offer');
            viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
        }
        if (DEBUG) console.log('[VIEWER] Generating ICE candidates');
    });

    viewer.signalingClient.on('sdpAnswer', async answer => {
        // Add the SDP answer to the peer connection
        if (DEBUG) console.log('[VIEWER] Received SDP answer');
        await viewer.peerConnection.setRemoteDescription(answer);
    });

    viewer.signalingClient.on('iceCandidate', candidate => {
        // Add the ICE candidate received from the MASTER to the peer connection
        if (DEBUG) console.log('[VIEWER] Received ICE candidate', candidate);
        viewer.peerConnection.addIceCandidate(candidate);
    });

    viewer.signalingClient.on('close', () => {
        console.log("[VIEWER][CLOSE] DISCONNECTED")
        if (type === 'QUEUE') {
            remove(position, formValues.channelName);
            stopViewer(viewer);
        }
    });

    viewer.signalingClient.on('error', error => {
        console.error('[VIEWER] Signaling client error: ', error);
    });


    /**
     * Send any ICE candidates to the other peer
     */
    viewer.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
        if (candidate) {
            if (DEBUG) console.log('[VIEWER] Generated ICE candidate');
            // When trickle ICE is enabled, send the ICE candidates as they are generated.
            if (formValues.useTrickleICE) {
                if (DEBUG) console.log('[VIEWER] Sending ICE candidate');
                viewer.signalingClient.sendIceCandidate(candidate);
            }
        } else {
            if (DEBUG) console.log('[VIEWER] All ICE candidates have been generated');
            // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
            if (!formValues.useTrickleICE) {
                if (DEBUG) console.log('[VIEWER] Sending SDP offer');
                viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
            }
        }
    });


    /**
    * As remote tracks are received, add them to the remote view.
    */
    viewer.peerConnection.addEventListener('track', async event => {
        console.log("[VIEWER][PEER CONNECTIION] Remote Treack: ", event)
        console.log('[VIEWER][PEER CONNECTIION] Log: Received remote track');

        if (oldViewer) {
            console.log('[VIEWER][TRACKER] STOP OLD VIEWER: ', oldViewer);
            await stopViewer(oldViewer);
            remoteView.srcObject = null
        }

        if (remoteView.srcObject) {
            console.log('[VIEWER][TRACKER] ALREADY HAVE OBJECT IN THER SOURCE');
            return;
        }

        console.log("------------------connect to player------------------")
        console.log('[VIEWER][TRACKER] SWITCH TRACKER ON PLAYER : ', viewer);

        viewer.remoteStream = event.streams[0];
        remoteView.srcObject = viewer.remoteStream;
    });


    /**
     * Check the peer connection status.
     */
    viewer.peerConnection.addEventListener('iceconnectionstatechange', event => {
        if (viewer) {
            console.log("[VIEWER][PEER CONNECTIION] Status: ", viewer.peerConnection.iceConnectionState)
            if (viewer?.peerConnection?.iceConnectionState === "disconnected") {
                console.log("[VIEWER][PEER CONNECTIION] DISCONNECTED: ", event)
                remove(position, formValues.channelName)
                stopViewer(viewer);
            }
            if (viewer?.peerConnection?.iceConnectionState === "failed") {
                console.log("[VIEWER][PEER CONNECTIION] FAILED: ", event)
                viewer.peerConnection.restartIce();
            }
        }
    });

    viewer.signalingClient.open();
    return viewer
}

export function stopViewer(viewer) {
    console.log('[VIEWER] Stopping viewer connection');
    if (viewer) {
        if (viewer.signalingClient) {
            viewer.signalingClient.close();
            viewer.signalingClient = null;
        }

        if (viewer.peerConnection) {
            viewer.peerConnection.close();
            viewer.peerConnection = null;
        }

        if (viewer.remoteStream) {
            viewer.remoteStream.getTracks().forEach(track => track.stop());
            viewer.remoteStream = null;
        }

        if (viewer.peerConnectionStatsInterval) {
            clearInterval(viewer.peerConnectionStatsInterval);
            viewer.peerConnectionStatsInterval = null;
        }

        if (viewer.remoteView) {
            viewer.remoteView.srcObject = null;
        }

        if (viewer.dataChannel) {
            viewer.dataChannel = null;
        }
    }

}

// function sendViewerMessage(message) {
//     if (viewer.dataChannel) {
//         try {
//             viewer.dataChannel.send(message);
//         } catch (e) {
//             console.error('[VIEWER] Send DataChannel: ', e.toString());
//         }
//     }
// }
