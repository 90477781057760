import i18next from 'i18next';
import localforage from 'localforage';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { signIn } from '../../service/api/auth';
import { calculateDistance } from '../../service/utils';
import { AuthContext } from '../auth/auth-context';
import { ServiceContext } from '../services/service-context';
import { GivePoints } from '../../service/api/third-party';


const VideowallContext = createContext()

const VideowallProvider = ({ children }) => {

    const { VideoStream } = useContext(ServiceContext);

    const navigate = useNavigate();
    const { currentUserSession } = useContext(AuthContext);
    const [videowallLoading, setVideowallLoading] = useState(true)
    const [language, setlLanguage] = useState('pt')
    const [userId, setUserId] = useState('pt')
    const [termsAccepted, setTermsAccepted] = useState(true)
    const [showFaqs, setShowFaqs] = useState(false)
    const [showSchedule, setShowSchedule] = useState(false)
    const [showTerms, setShowTerms] = useState(false)
    const [permissionCamara, setPermissionCamara] = useState(false)
    const [permissionCamaraRequested, setPermissionCamaraRequested] = useState(false)
    const [eventActive, setEventActive] = useState(false)
    const [guid, setGuid] = useState(null)
    const [insideLocation, setInsideLocation] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        setUserId(searchParams.get("userid"));
        if(userId) {
            GivePoints({input: {userId}})
        }
    }, [userId])





    const onSelectLang = async (lang) => {
        await localforage.setItem('lang', lang)
        setlLanguage(lang)
        if (lang) i18next.changeLanguage(lang);

    }

    const onAcceptTerms = async (value) => {
        await localforage.setItem('terms', value)
        setTermsAccepted(value)
    }

    const onBackTerms = () => {
        setTermsAccepted(false)
        setlLanguage('pt')
    }

    const requestCamPermissions = async () => {
        await navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            var track = stream.getTracks()[0];
            track.stop();
            setPermissionCamara(true)
            setPermissionCamaraRequested(true)
        }).catch(error => {
            setPermissionCamara(false)
            setPermissionCamaraRequested(true)
        })
    }

    const showCam = async () => {
        return new Promise(async (resolve) => {
            await navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
                resolve(stream)
            }).catch(error => {
                resolve(null)
            })
        })
    }

    const authAnonymousUser = async () => {
        try {
            let haveSession = await currentUserSession();
            if (!haveSession) {
                let { data } = await VideoStream.getAnonymousUser();
                await signIn(data.getAnonymousUser.username, data.getAnonymousUser.password);
            }
        } catch (error) {
            throw error
        }
    }

    const getListConfigurationCF = async () => {
        return await VideoStream.listConfigurationCloudfront();
    }

    const checkEvent = async () => {
        await authAnonymousUser();
        const resp = await getListConfigurationCF();
        const eventDatesArr = resp.filter((item) => item.SK === "EventDate")[0]

        if(eventDatesArr && eventDatesArr.SK === "EventDate" && eventDatesArr.value.length > 0) {
            let newArray = eventDatesArr.value.split('},')
            let jsonArrayParse = newArray.map((itemJson, index) => {
            let itemJsonFix = itemJson
            if(index !== newArray.length - 1)
                itemJsonFix = itemJson+"}"
            return JSON.parse(itemJsonFix)
            })

            const events = jsonArrayParse;

            const currentTimestamp = Date.now()

            let onDate = false;

            events.forEach(el => {
                if (currentTimestamp >= el.startTime && currentTimestamp <= el.endTime) {
                    onDate = true
                    return;
                }
            });


            if (onDate) {
                const status = await VideoStream.getEventStatusCloudfront()
                const isActive = status === 'ACTIVE'
                isActive ? navigate('/') : navigate('/next-event')
                setEventActive(isActive)
                setVideowallLoading(false)
            } else {
                navigate('/next-event')
                setVideowallLoading(false)
            }
        }else {
            navigate('/next-event')
            setVideowallLoading(false)
        }
        return null
    }

    const updateChannelStatus = async (channel, status) => {
        const data = {
            name: channel?.name,
            //status: 'AVAILABLE',
            status: status,
            clientId: guid
        }
        await VideoStream.updateChannelStatus(data)
    }


    const checkLocation = async (latitude, longitude, accuracy, positions) => {
        return new Promise( async (resolve, reject) => {
            try {
                let result = false;
                let newArray = positions.split('},')

                let locations = newArray.map((itemJson, index) => {
                    let itemJsonFix = itemJson
                    if(index !== newArray.length - 1)
                        itemJsonFix = itemJson+"}"
                    return JSON.parse(itemJsonFix)
                })

                locations.forEach(data => {
                    let dist = calculateDistance(latitude, longitude, +data.lat, +data.long)
                    dist = (dist - accuracy)
                    if (dist <= parseFloat(data.radius)) {
                        result = true;
                        return
                    }
                })
                resolve(result)
            } catch (error) {
                resolve(false)
            }
        })
    }


    useEffect(() => {
        const checkSettings = async () => {
            const lang = await localforage.getItem('lang')
            const terms = await localforage.getItem('terms')
            if (!guid) {
                const _guid = uuidv4();
                setGuid(_guid)
            }
            i18next.changeLanguage(lang || 'pt');
            setlLanguage(lang || 'pt')
            setTermsAccepted(true)
        }

        checkSettings()
        checkEvent()
    }, [])


    useEffect(() => {
        if (termsAccepted && eventActive) {
            requestCamPermissions()
        }
    }, [termsAccepted, eventActive])




    useEffect(() => {
        let intervalId = null;
        const checkEventStatus = async () => {
            intervalId = setInterval(async () => {
                if (termsAccepted) {
                    checkEvent()
                }
            }, 5000);
        }
        checkEventStatus()
        return () => clearInterval(intervalId);
    }, [termsAccepted]);








    return (
        <VideowallContext.Provider
            value={{
                videowallLoading, setVideowallLoading,
                onAcceptTerms, termsAccepted, onBackTerms,
                showTerms, setShowTerms,
                language, onSelectLang,
                showFaqs, setShowFaqs,
                showSchedule, setShowSchedule,
                requestCamPermissions, showCam,
                checkEvent,
                permissionCamara, setPermissionCamara,
                permissionCamaraRequested,
                updateChannelStatus, guid,
                checkLocation,
                userId
            }}>
            {children}
        </VideowallContext.Provider>
    );

}

function useVideowall() {
    const context = useContext(VideowallContext);
    if (!context) {
        throw new Error('useVideowall must be used within an VideowallProvider.');
    }
    return context;
}


export { VideowallProvider, useVideowall };
