const Styles = theme => ({
    root: {
    },
    video: {
        width: "100%",
        height: "100%",
        objectFit: 'cover',
        position: 'absolute',

    },
    actionRemove: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        backgroundColor: '#B0191A',
        borderRadius: "40px",
        bottom: 20,
        right: 20,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        cursor: 'pointer'
    },
    actionExtend: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        backgroundColor: '#FFFFFF',
        borderRadius: "40px",
        bottom: 20,
        right: 70,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        cursor: 'pointer'
    }
})

export default Styles;
