import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Styles from './style';
import { Add } from '@material-ui/icons'
import GeoLocationItem from './components/GeoLocationItem';

const useStyles = makeStyles(Styles);

const GeoLocationList = ({onLocationChangeHandler, removeNewLocationScheduleHandler, addNewLocationScheduleHandler, rowsLocation, isDidabled}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [locationRows, setLocationRows] = useState([]);

    const renderLocationRows = () => {
        let newRenderRows = [];
        if(rowsLocation.renders){
            rowsLocation.renders.forEach((row, index) => {
                newRenderRows.push(
                    <GeoLocationItem key={row.id} firstItem={index} item={row} onLocationChangeHandler={onLocationChangeHandler} removeNewLocationScheduleHandler={removeNewLocationScheduleHandler} isDidabled={isDidabled}/>
                )
            })
        }
        setLocationRows(newRenderRows);
    };

    useEffect( () => {
        renderLocationRows()
    }, [rowsLocation, isDidabled])

  return (
    <div className={classes.root}>
        <div className={classes.content}>
            {locationRows && locationRows.length > 0 && locationRows}
            {isDidabled ? null : <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div onClick={() => addNewLocationScheduleHandler()} style={{ cursor: 'pointer', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', backgroundColor: '#064873',  marginTop: '1rem' }}>
                    <Add style={{ color: '#ffffff', fontSize: "19px" }} />
                </div>
            </div>}
        </div>
    </div>
  )
}

export default GeoLocationList