import * as AuthService from '../../service/api/auth/';

export   const signin = async (username, password) => {
    try {
        const result = await AuthService.signIn(username, password);

        return result;
    } catch (error) {
        const errorCode = error.code
        switch (errorCode) {
            case 'NetworkError':
                console.log("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                break;
            case 'NotAuthorizedException':
                console.log("error", "notifications-signin-NotAuthorizedException-message", "notifications-signin-NotAuthorizedException-title");
                break;
            case 'UserNotFoundException':
                console.log("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                break;
            default:
                console.log("error", "notifications-default-Error-message", "notifications-default-Error-title");
                break;
        }
    }
}


export const currentUserSession = async () => {
    try {
        let result = await AuthService.currentSession();
        return result;
    } catch (error) {
        switch (error) {
            case 'No current user':
                console.error(error);
                break;
            default:
                console.log("error", "notifications-default-Error-message", "notifications-default-Error-title");
                break;
        }
    }
  };
