import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { Paper, Grid, TextField, Button, CircularProgress, } from '@material-ui/core';
import { TextFields, Subject, ArrowBackIosOutlined, Today, Add, Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// import { useNotifications } from '../../../../components/notification-snackbar';
import { ServiceContext } from '../../../../../../context/services/service-context';
import Styles from './style.js';

const useStyles = makeStyles(Styles);



const ScheduleUpdateView = (props) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const { t } = useTranslation();
    const { VideoStream } = useContext(ServiceContext);
    const [schedule, setSchedule] = useState({});
    const [rows, setRows] = useState({ renders: null })


    useEffect(() => {
        getScheduleEvent(id, state)
    }, [])

    const updateScheduleHandler = useFormik({
        initialValues: {
            id: '',
            name: '',
            description: ''
        },
        onSubmit: async (values) => {
            try {
                let datesArray = []
                rows?.renders?.forEach(element => {
                    console.log(element)
                    datesArray.push({
                        startTimestamp: new Date(element.startTimestamp).getTime(),
                        endTimestamp: new Date(element.endTimestamp).getTime()
                    })
                });
                values.dates = datesArray;
               const result = await VideoStream.udpateEventSchedule(values);
                if (result) {
                    // triggerNotification("warning", "notifications-default-MatchPassword-message", "notifications-default-MatchPassword-title")
                    navigate('/bo/video-stream/schedule/');
                }
            } catch (error) {
                throw error
            }
        },
    });

    const getScheduleEvent = async (id, state) => {
        try {
            if (state == null && id) {
                let result = await VideoStream.getEventSchedule(id);
                setSchedule(result);
                setData(result)
            } else {
                const _dates = state.dates
                const array = _dates.map((it, index) => {
                    return {
                        id: `data-group-${index}`,
                        startTimestamp: it.startTimestamp,
                        endTimestamp: it.endTimestamp
                    }
                })
                setRows({ renders: array });
                setSchedule(state);
                setData(state)
            }
        } catch (error) {
            throw error
        }
    }

    const setData = (data) => {
        try {
            updateScheduleHandler.setFieldValue('id', data.id);
            updateScheduleHandler.setFieldValue('name', data.name);
            updateScheduleHandler.setFieldValue('description', data.description);
        } catch (error) {
            throw error
        }
    }

    function addNewDateScheduleHandler() {
        let array = Array.isArray(rows.renders) ? rows.renders : []
        array.push({ id: `data-group-${array.length + 1}` })
        setRows({ renders: array });
    }


    function removeNewDateScheduleHandler(id) {
        let array = rows.renders;
        array = array.filter(item => item.id !== id);
        setRows({ renders: array });
    }

    const onDateChangeHandler = (event) => {
        let id = event.target.id;
        let name = event.target.name;
        let value = event.target.value;

        const dates = rows.renders;

        let exists = dates.find(item => item.id === id);
        if (exists) {
            dates.filter(item => {
                if (item.id === id) {
                    item[name] = value;
                }
            })
        } else {
            let newDate = { id: id };
            newDate[name] = value;
            dates.push(newDate);
        }
        setRows({ renders: dates });
    }


    return (

        <div className={classes.root}>
            <div className={classes.title}>{t('bo-schedule-title')}</div>
            <div className={classes.label}>{updateScheduleHandler.values.name}</div>
            <br />
            <div className={classes.header} onClick={() => { navigate('/bo/video-stream/schedule') }}>
                <ArrowBackIosOutlined className={classes.icons} />
                <p>{t('bo-action-btn-label-back')}</p>
            </div>
            <br />
            <div className={classes.grid}>
                {
                    schedule ?
                        <Grid container spacing={4}>
                            <Grid item sm>
                                <br />
                                <Paper elevation={0} className={classes.paper}>
                                    <form onSubmit={updateScheduleHandler.handleSubmit} className={classes.form} >
                                        <TextField
                                            id="name"
                                            type='text'
                                            label={t('bo-schedule-create-input1-label')}
                                            placeholder={t('bo-schedule-create-input1-label')}
                                            variant="outlined"
                                            InputProps={{ startAdornment: <TextFields className={classes.icons} /> }}
                                            onChange={updateScheduleHandler.handleChange}
                                            defaultValue={updateScheduleHandler.values.name}
                                            value={updateScheduleHandler.values.name}
                                            required
                                        />
                                        <TextField
                                            id="description"
                                            type='text'
                                            label={t('bo-schedule-create-input2-label')}
                                            placeholder={t('bo-schedule-create-input2-label')}
                                            variant="outlined"
                                            InputProps={{ startAdornment: <Subject className={classes.icons} /> }}
                                            onChange={updateScheduleHandler.handleChange}
                                            defaultValue={updateScheduleHandler.values.description}
                                            value={updateScheduleHandler.values.description}
                                            rows={8}
                                            multiline
                                            classes={{
                                                root: classes.textArea,
                                            }}
                                            required
                                        />

                                        {
                                            rows.renders ?
                                                rows.renders.length > 0 ?
                                                    rows.renders.map((prop) =>
                                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }} key={prop.id}>
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                <div style={{ flex: 1, display: 'flex', fontWeight: 400, fontSize: "12px", color: 'rgba(0,0,0,0.54)', }}>{t('bo-schedule-create-input3-label')}</div>
                                                                <div style={{ width: '20px' }} />
                                                                <div style={{ flex: 1, display: 'flex', fontWeight: 400, fontSize: "12px", color: 'rgba(0,0,0,0.54)' }}>{t('bo-schedule-create-input4-label')}</div>
                                                                <div style={{ width: '39px' }} />
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <TextField
                                                                    id={prop.id}
                                                                    // id={}
                                                                    name="startTimestamp"
                                                                    type='datetime-local'
                                                                    variant="outlined"
                                                                    placeholder={t('bo-schedule-create-input2-label')}
                                                                    InputProps={{
                                                                        startAdornment: <Today className={classes.icons} />
                                                                    }}
                                                                    onChange={onDateChangeHandler}
                                                                    value={moment(new Date(prop.startTimestamp)).format('YYYY-MM-DDTHH:mm')}
                                                                    style={{ flex: 1 }}
                                                                    required
                                                                />
                                                                <div style={{ width: '20px' }} />
                                                                <TextField
                                                                    id={prop.id}
                                                                    name="endTimestamp"
                                                                    type='datetime-local'
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        startAdornment: <Today className={classes.icons} />
                                                                    }}
                                                                    style={{ flex: 1 }}
                                                                    onChange={onDateChangeHandler}
                                                                    value={moment(new Date(prop.endTimestamp)).format('YYYY-MM-DDTHH:mm')}
                                                                    required
                                                                />
                                                                <div style={{ width: '20px' }}/>
                                                                <div onClick={() => removeNewDateScheduleHandler(prop.id)} key={`box-button-delete-${prop.id}`}
                                                                    style={{ cursor: 'pointer', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', backgroundColor: '#064873' }}>
                                                                    <Delete style={{ color: '#ffffff', fontSize: "19px" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    : null
                                                : null
                                        }
                    
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            <div onClick={() => addNewDateScheduleHandler()} style={{ cursor: 'pointer', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', backgroundColor: '#064873' }}>
                                                <Add style={{ color: '#ffffff', fontSize: "19px" }} />
                                            </div>
                                        </div>
                                        <Button type='submit' variant="contained" color='primary' disableElevation classes={{ root: classes.button }}>{
                                            updateScheduleHandler.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('bo-schedule-update-btn-label')
                                        }</Button>
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
                        :
                        <div style={{ flex: '1', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={50} className={classes.progress} />
                        </div>
                }
            </div>
        </div>
    );
}

export default ScheduleUpdateView;