export const Styles = theme => ({
    root: {
        width: '100vw',
        minHeight: '100vh',
        overflow: 'hidden',
        padding: '0px',
        background: 'linear-gradient(top, #EEDF17 0%, #EEDF17 10%, #EBE6B3 45%, #EAE9E9 87%)',
        backgroundColor: '#EEDF17',
        boxSizing: 'border-box',
    },
    loadingPop: {
        width: '100vw',
        height: '100vh',
        padding: '0px',
        backgroundColor: '#333333'
    },
    title: {
        display: 'block',
       color:  'rgba(226, 49, 114, 1)',
       fontFamily: 'RamaGothicSB',
       fontSize: 20,
       padding: 10,
       textAlign: 'center',
    },
    datesContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin:'20px auto',
        width: '80%'
    },

    dateButton: {
        alignItems: 'center',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        borderRadius: 28,
        boxShadow: '0px 0px 10px rgba(0,0,0, 0.2)' ,
        color:  'rgba(226, 49, 114, 1)',
        cursor: 'pointer',
        display: 'flex',
        fontFamily: 'RamaGothicSB',
        fontSize: 22,
        height: 90,
        justifyContent: 'space-between',
        margin: '10px 0',
        padding: 20,
        width: '100%'
    },

    galleryContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        paddingBottom: '95px'
    },

    imageContainer: {
        aspectRatio: '1/1',
        display: 'block',
        width: '30%',
        overflow: 'hidden',
        borderRadius: 28,
        margin: '2% 1.5%'
    },

    imageSize: {
        display: 'block',
        objectFit: 'cover',
        width: '100%',
        height: '100%'
    },
    imageBigContainer: {
        display: 'block',
        width: '96%',
        overflow: 'hidden',
        borderRadius: 28,
        boxSizing: 'border-box',
        margin: '10px auto'
    },

    imageBigSize: {
        display: 'block',
        objectFit: 'cover',
        width: '100%',
        height: 'auto'
    },

    noUser: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '100%',
        color:  'rgba(226, 49, 114, 1)',
       fontFamily: 'RamaGothicSB',
       fontSize: 40
    },

    backButton: {
        backgroundColor: 'white',
        boxSizing: 'border-box',
        borderRadius: 28,
        boxShadow: '0px 0px 10px rgba(0,0,0, 0.2)' ,
        color: 'black',
        fontFamily: 'RamaGothicSB',
        fontSize: 18,
        letterSpacing: 1,
        textTransform: 'uppercase',
        padding: '18px 20px',
        position: 'fixed',
        top: 'auto',
        bottom: 32,
        left: 'calc( 50% - 83px)'
    },

    buttonBottomContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 20,
        width: '96%',
        margin:'0 auto'
    },

    backBottomButton: {
        backgroundColor: 'white',
        boxSizing: 'border-box',
        borderRadius: 28,
        boxShadow: '0px 0px 10px rgba(0,0,0, 0.2)' ,
        color: 'black',
        fontFamily: 'RamaGothicSB',
        fontSize: 18,
        letterSpacing: 1,
        textTransform: 'uppercase',
        padding: '18px 20px',
        minWidth: 120,
        textAlign: 'center',

        cursor: 'pointer',
    },
    downloadBottomButton: {
        backgroundColor: 'rgba(226, 49, 114, 1)',
        boxSizing: 'border-box',
        borderRadius: 28,
        boxShadow: '0px 0px 10px rgba(0,0,0, 0.2)' ,
        color: 'white',
        cursor: 'pointer',
        display: 'flex',
        fontFamily: 'RamaGothicSB',
        fontSize: 18,
        letterSpacing: 1,
        textTransform: 'uppercase',
        textDecoration: 'none',
        padding: '18px 20px',
        minWidth: 120,
        textAlign: 'center',
        "& img": {
            marginLeft: 10
        }
    },

});
