import { makeStyles } from '@material-ui/core';
import i18next from 'i18next';
import localforage from 'localforage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useVideowall } from '../../../context/Videowall';
import Button from '../Button';



const LANGS_CHANGE = {
    pt: 'English',
    en: 'Português'
}


const Footer = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { setShowTerms } = useVideowall()


    const onChangeLang = async (lg) => {
        i18next.changeLanguage(lg);
        await localforage.setItem('lang', lg)

    }


    const termsHandler = () => {
        setShowTerms(true)
    }



    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <Button
                    key="laguage"
                    label={LANGS_CHANGE.en}
                    onClick={() => onChangeLang('pt')}
                    styles={{boxSizing: 'border-box'}}
                    active={i18next.language === 'pt'}
                />
                <Button
                    key="laguageen"
                    label={LANGS_CHANGE.pt}
                    onClick={() => onChangeLang('en')}
                    styles={{boxSizing: 'border-box'}}
                    active={i18next.language === 'en'}
                />
                {/* <Button
                    key="terms"
                    label={t('videowall-app-footer-terms')}
                    onClick={() => termsHandler()}
                    styles={{ backgroundColor: '#064873', color: '#ffffff', padding: '4% 0 4%', width: '27%', boxSizing: 'border-box', fontSize: '0.7rem', lineHeight: 1}}
                /> */}
            </div>
            <div className={classes.copyRight}>
                {/* <span>© Innowave 2023</span> */}
            </div>
        </div>
    )
}

export default Footer


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '12px',
        background: 'none',
    },
    row: {
        display: 'flex',
        margin: '3px auto',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2%'
    },
    copyRight: {
        marginTop: '12px',
        '& span': {
            font: 'normal normal normal 0.6rem OpenSans',
            margin: 0,
            color: '#FFF'
        }
    }
}))
