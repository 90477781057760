const Styles = theme => ({
    root: {
        width: '80%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
        [theme.breakpoints.between('0', '600')]: {
            fontSize: '1.7rem',
        },
    },
    grid: {
        padding: '0px',
        // height: '400px',
        "& .MuiContainer-maxWidthLg":{
            padding: '0px !important',
            maxWidth: '1400px '
        }
    },
    boxHeader: {
        marginBottom: "40px",
        display: "flex",
        alignItems: "center",
    },
    boxButton: {
        marginLeft: "auto",
    },
    table:{
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        }
    },
    dialogColors: {
       '& .MuiDialog-paper': {
        backgroundColor : '#333'
       },
       '& .MuiDialogContentText-root': {
        color : '#FFF'
       },
    }
});

export default Styles;