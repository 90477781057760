
import Assets from '../../assets';


const Styles = theme => ({
    root: {
        height: '100vh',
        width: '100vw',
        padding: '0px',
        background: 'linear-gradient(top, #EEDF17 0%, #EEDF17 10%, #EBE6B3 45%, #EAE9E9 87%)',
        backgroundColor:'#EEDF17',
    },
    bgDot:{
        height: '100vh',
        width: '100vw',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        //backgroundImage: `url(${Assets.Images.videowall_background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        flexDirection: 'column',
        position: 'fixed',
        overflowY: 'auto',
        justifyContent: 'flex-start',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '1.5rem',
    },
    top: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: '4px auto 31px',
        '& img': {
            display: 'block',
            objectFit: 'contain',
            maxWidth: '78px',
            margin: '0 auto'
        }
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'center',
        color: '#000',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize:'2.6rem',
        width: '90%',
        margin: '30px auto',
        fontFamily: 'RamaGothicSB',
        letterSpacing: '1px'
    }
})

export default Styles;
