import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Styles from './style';
import { TextField } from '@material-ui/core'
import { Delete, PinDrop, TextFields, Today, TrackChanges } from '@material-ui/icons'
import moment from 'moment';
import { useNotifications } from '../../../../Shared/NotificationSnackbar';


const useStyles = makeStyles(Styles);

const GeoLocationItem = ({item, onLocationChangeHandler, removeNewLocationScheduleHandler, firstItem, isDidabled}) => {

    const { t } = useTranslation();
    const classes = useStyles();

    const { triggerNotification } = useNotifications();
    
    const [latitude, setLatitude] = useState(item.lat ?? '');
    const [longitude, setLongitude] = useState(item.long ?? '');
    const [radius, setRadius] = useState(item.radius ?? '');
    const [location, setLocation] = useState(item.location ??'');

    const onlatitudeChangeHandler = (event) => {
            setLatitude(event.target.value);
            onLocationChangeHandler(event);
    }
    
    const onlongitudeChangeHandler = (event) => {
            setLongitude(event.target.value);
            onLocationChangeHandler(event);
    }
    const onRadiusChangeHandler = (event) => {
            setRadius(event.target.value);
            onLocationChangeHandler(event);
    }
    
    const onLocationNameChangeHandler = (event) => {
            setLocation(event.target.value);
            onLocationChangeHandler(event);
    }

  return (
    <div className={classes.datesContent}>
        <div className={classes.dateInputName}>
            <TextField
                id={item.id}
                name="location"
                type='text'
                variant="outlined"
                placeholder={t('bo-event-location-place')}
                label={t('bo-event-location-place')}
                InputProps={{
                    startAdornment: <TextFields className={classes.icons} />
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={onLocationNameChangeHandler}
                value={location}
                style={{ flex: 1 }}
                required
                disabled={isDidabled}
            />
        </div>
        <div className={classes.dateInput}>
            <TextField
                id={item.id}
                name="lat"
                type='text'
                variant="outlined"
                placeholder={t('bo-event-location-latitude')}
                label={t('bo-event-location-latitude')}
                InputProps={{
                    startAdornment: <PinDrop className={classes.icons} />
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={onlatitudeChangeHandler}
                value={latitude}
                style={{ flex: 1 }}
                required
                disabled={isDidabled}
            />
        </div>
        <div className={classes.dateInput}>
            <TextField
                id={item.id}
                name="long"
                type='text'
                variant="outlined"
                placeholder={t('bo-event-location-longitude')}
                label={t('bo-event-location-longitude')}
                InputProps={{
                    startAdornment: <PinDrop className={classes.icons} />
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                value={longitude}
                style={{ flex: 1 }}
                onChange={onlongitudeChangeHandler}
                required
                disabled={isDidabled}
            />
        </div>
        <div className={classes.dateInput}>
            <TextField
                id={item.id}
                name="radius"
                type='text'
                variant="outlined"
                placeholder={t('bo-event-location-radius')}
                label={t('bo-event-location-radius')}
                InputProps={{
                    startAdornment: <TrackChanges className={classes.icons} />
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                value={radius}
                style={{ flex: 1 }}
                onChange={onRadiusChangeHandler}
                required
                disabled={isDidabled}
            />
        </div>
        {firstItem !== 0 && !isDidabled && <div onClick={() => removeNewLocationScheduleHandler(item.id)} className={classes.deleteBtn}>
            <Delete style={{ color: '#ffffff', fontSize: "19px" }} />
        </div>}
    </div>
  )
}

export default GeoLocationItem