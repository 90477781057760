import React, { useContext, useEffect, useState } from 'react';
import Styles from "./style";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { Box, Button, CircularProgress, Paper, TextField } from '@material-ui/core';
import { ServiceContext } from '../../../../context/services/service-context';
import { useNotifications } from '../../../../components/Shared/NotificationSnackbar';

const useStyles = makeStyles(Styles);

const ThirdPartyForm = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const { ThirdParty } = useContext(ServiceContext);
  const location = useLocation();
  const { triggerNotification } = useNotifications();
  const [hasUser, setHasUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userApi, setUserApi] = useState(location.state?.userApi ? 
    {
      id: location.state?.userApi.id,
      apiURL: location.state?.userApi.apiURL,
      cognitoAppClientID: location.state?.userApi.cognitoAppClientID,
      cognitoUserPoolID: location.state?.userApi.cognitoUserPoolID,
      kmsKeyARN: location.state?.userApi.kmsKeyARN,
      project: location.state?.userApi.project,
      secretARN: location.state?.userApi.secretARN,
      username: location.state?.userApi.username,
    } : null);

  useEffect(() => {
    if(userApi){
      setHasUser(true);
      setIsEdit(true);
    }

  }, [])

  const onChangeInput = (event) => {
    setUserApi((prev) => {return {...prev, [event.target.id]: event.target.value}})
  };

  const submitFromUserApi = async() => {
    setIsSubmitting(true)
    try{
      if(!hasUser){
        if(isEdit){
          console.log("Please enter")
          const result = await ThirdParty.updateCustomEventNotification({input: {...userApi, eventType: 'GRAPHQL_API'}})
          if(result) {
            triggerNotification("success", t("notifications-success-title"))
          }
        }else{
          const result = await ThirdParty.createCustomEventNotification({input: {...userApi, eventType: 'GRAPHQL_API'}})
          if(result) {
            triggerNotification("success", t("notifications-success-title"))
          }
        }}else{
          setHasUser(false)
        }
    }catch(e) {
      console.log(e)
    }finally{
      setIsSubmitting(false)
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.boxHeader}>
        <div className={classes.title}>{t('thirdparty-form-title')}</div>
      </Box>
      <Paper elevation={1}>
      <form className={classes.root} autoComplete="off" onSubmit={(a) => {a.preventDefault();  submitFromUserApi()}}>
        <TextField id="username" label="Username" variant="outlined" required value={userApi?.username || ''} disabled={hasUser} onChange={(event) => onChangeInput(event)} InputLabelProps={{shrink: true}}/>
        <TextField id="cognitoUserPoolID" label="Cognito ID" variant="outlined" required value={userApi?.cognitoUserPoolID || ''} disabled={hasUser} onChange={(event) => onChangeInput(event)} InputLabelProps={{shrink: true}}/>
        <TextField id="project" label="Project" variant="outlined" required value={userApi?.project || ''} disabled={hasUser || isEdit} onChange={(event) => onChangeInput(event)} InputLabelProps={{shrink: true}}/>
        <TextField id="cognitoAppClientID" label="Cognito App Client ID" variant="outlined" required value={userApi?.cognitoAppClientID || ''} disabled={hasUser} onChange={(event) => onChangeInput(event)} InputLabelProps={{shrink: true}}/>
        <TextField id="secretARN" label="Secret ARN" variant="outlined" required value={userApi?.secretARN || ''} disabled={hasUser} onChange={(event) => onChangeInput(event)} InputLabelProps={{shrink: true}}/>
        <TextField id="kmsKeyARN" label="Kms Key ARN" variant="outlined" required value={userApi?.kmsKeyARN || ''} disabled={hasUser} onChange={(event) => onChangeInput(event)} InputLabelProps={{shrink: true}}/>
        <TextField id="apiURL" label="AppSync URL" variant="outlined" required value={userApi?.apiURL || ''} disabled={hasUser} onChange={(event) => onChangeInput(event)} InputLabelProps={{shrink: true}}/>
        
        <Button
            variant='contained'
           type='submit'
        >{isSubmitting ? <CircularProgress size={24} color={'inherit'}/> : hasUser ? t("edit") : t("submit")}</Button>
      </form>
      </Paper>
    </div>
  )
}

export default ThirdPartyForm