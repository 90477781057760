const Styles = theme => ({
    root: {
        width: '80%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
     boxHeader: {
        marginBottom: "40px",
        display: "flex",
        alignItems: "center",
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
        [theme.breakpoints.between('0', '600')]: {
            fontSize: '1.7rem',
        },
    },
})

export default Styles