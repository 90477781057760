import React, { useCallback, forwardRef, useState, useEffect, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper, Button, CircularProgress, Switch } from '@material-ui/core/';
import Styles from './style';
import { VIDEOWALL } from '../../../../layout/config/videowall.config';
import { TextFields, Today } from '@material-ui/icons';
import DateList from '../../../../components/Backoffice/DateList';
import GeoLocationList from '../../../../components/Backoffice/GeoLocationList';
import { ServiceContext } from '../../../../context/services/service-context';
import moment from 'moment';
import ImageUpdate from '../../../../components/Backoffice/ImageUpdate';

const useStyles = makeStyles(Styles);

const LOGOS = VIDEOWALL.logos

const Events = () => {
  const { t } = useTranslation();
  const { VideoStream } = useContext(ServiceContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false)

  const [dates, setDates] = useState([])
  const [rows, setRows] = useState({ renders: null })
  const [locations, setLocations] = useState([])
  const [rowsLocation, setRowsLocation] = useState({ renders: null })
  const [application, setApplication] = useState({
    EventDate: [],
    EventDisplayName: '',
    GeoLocation: [],
    LocalizationEnabled: true,
  })
  const [editDisabled, setEditDisabled] = useState(true);
  const [imageFile, setImageFile] = useState('');
  const [apectRatioImage, setApectRatioImage] = useState(false);

  const onDateChangeHandler = (event) => {
    let id = event.target.id;
    let name = event.target.name;
    let value = event.target.value;

    let exists = dates.find(item => item.id === id);
    if (exists) {
        dates.forEach(item => {
            if (item.id === id) {
                item[name] = moment(value).valueOf();
            }
        })
    } else {
        let newDate = { id: id };
        newDate[name] = value;
        dates.push(newDate);
    }
    setDates(dates);
    setApplication(prev => ({...prev, EventDate: dates}));
  }

  const addNewDateScheduleHandler = () => {
    let array = Array.isArray(rows.renders) ? rows.renders : []
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    array.push({ id: `data-group-${array.length + 1 + timestamp}` })
    setRows({ renders: array });
  }

  const removeNewDateScheduleHandler = (id) => {
    let array = rows.renders;
    array = array.filter(item => item.id !== id);
    setRows({ renders: array });
    let exists = dates.find(item => item.id === id);
    if (exists) {
      dates.forEach((item, index) => {
          if (item.id === id) {
              dates.splice(index, 1);
          }
      })
      setDates(dates);
    }
  }

  useEffect(() => {
    if(rows.renders === null){
      addNewDateScheduleHandler()
    }
    if(rowsLocation.renders === null){
      addNewLocationScheduleHandler()
    }
  });

  useEffect(() => {
    listEventConfig();
  }, [])
  
  useEffect(() => setRows({renders: application.EventDate}), [application])
  useEffect(() => setRowsLocation({renders: application.GeoLocation}), [application])
  
  const addNewLocationScheduleHandler = () => {
    let array = Array.isArray(rowsLocation.renders) ? rowsLocation.renders : []
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    array.push({ id: `location-group-${array.length + 1 + timestamp}` })
    setRowsLocation({renders: array});
  }

  const removeNewLocationScheduleHandler = (id) => {
    let array = rowsLocation.renders;
    array = array.filter(item => item.id !== id);

    setRowsLocation({ renders: array });

    let exists = locations.find(item => item.id === id);
    if (exists) {
      locations.forEach((item, index) => {
          if (item.id === id) {
              locations.splice(index, 1);
          }
      })
      setLocations(locations);
    }
  }

  const onLocationChangeHandler = (event) => {
    let id = event.target.id;
    let name = event.target.name;
    let value = event.target.value;

    let exists = locations.find(item => item.id === id);
    if (exists) {
      locations.forEach(item => {
            if (item.id === id) {
                item[name] = value;
            }
        })
    } else {
        let newDate = { id: id };
        newDate[name] = value;
        locations.push(newDate);
    }
    setLocations(locations);
    setApplication(prev => ({...prev, GeoLocation: locations}));
  }

  const listEventConfig = async () => {
    const result = await VideoStream.listConfigurations("APPLICATION");
    setApplicationConfigState(result);
  }

  const setApplicationConfigState = (result) => {
    result.forEach((item) => {
      if(item.SK === "LocalizationEnabled"){
        setApplication(prev => ({...prev, [item.SK]: (item.value === "true")}));
      }
      if(item.SK === "EventDate" ){
        if (item.value && item.value.length > 0){
          let newArray = item.value.split('},')
          let jsonArrayParse = newArray.map((itemJson, index) => {
            let itemJsonFix = itemJson
            if(index !== newArray.length - 1)
              itemJsonFix = itemJson+"}"
            return JSON.parse(itemJsonFix)
          })
          setDates(jsonArrayParse)
          setApplication(prev => ({...prev, [item.SK]: jsonArrayParse}));
        }else{
          setApplication(prev => ({...prev, [item.SK]: []}));
        }
      }
      if(item.SK === "GeoLocation"){
        if (item.value && item.value.length > 0){
          let newArray = item.value.split('},')
          let jsonArrayParse = newArray.map((itemJson, index) => {
            let itemJsonFix = itemJson
            if(index !== newArray.length - 1)
              itemJsonFix = itemJson+"}"
            return JSON.parse(itemJsonFix)
          })
          setLocations(jsonArrayParse)
          setApplication(prev => ({...prev, [item.SK]: jsonArrayParse}));
        }else{
          setApplication(prev => ({...prev, [item.SK]: []}));
        }
      }
      if(item.SK === "EventDisplayName")
        setApplication(prev => ({...prev, [item.SK]: item.value}));
    })
  }

  const updateApplicationConfig = async () => {
    setIsLoading(true)
    await VideoStream.updateConfiguration({PK: "APPLICATION", SK: "EventDisplayName", value: application.EventDisplayName})
    let eventDates = [];
    application.EventDate.forEach(item => {
      eventDates.push(JSON.stringify(item))
    })
    await VideoStream.updateConfiguration({PK: "APPLICATION", SK: "EventDate", value: eventDates.toString()})
    let eventLocations = [];
    application.GeoLocation.forEach(item => {
      eventLocations.push(JSON.stringify(item))
    })
    await VideoStream.updateConfiguration({PK: "APPLICATION", SK: "GeoLocation", value: eventLocations.toString()})
    await VideoStream.updateConfiguration({PK: "APPLICATION", SK: "LocalizationEnabled", value: application.LocalizationEnabled})


    if(apectRatioImage){
      const urlSigned = await VideoStream.getS3SignedUrl(imageFile.fileName)
      await VideoStream.updateEventImageDisplay(urlSigned.signedUrl, imageFile)
    }
    
    setIsLoading(false)
    setEditDisabled(true)
  }
  
  const onChangeImage = async (fileImage, aspect) => {
    if(fileImage && aspect){
      setApectRatioImage(aspect);
      setImageFile(fileImage)
    }else{
      setApectRatioImage(aspect);
    }
  }


  return (
    <div className={classes.root}>
      <div className={classes.title}>{t('bo-events-title')}</div>
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.contentInput}>
          <TextField 
            label={t('bo-event-display-name')} 
            value={application.EventDisplayName} 
            placeholder={t('bo-event-display-name')} 
            variant="outlined" id="event-displey-name" 
            InputProps={{ startAdornment: <TextFields className={classes.icons} />  }}
            onChange={event => setApplication(prev => ({...prev, EventDisplayName: event.target.value}))}
            disabled={editDisabled}/>
        </div>
        <ImageUpdate onChangeImage={onChangeImage} disabled={editDisabled} fileName="logo" height={'auto'} width="50%" aspectRatio={2} aspectRatioText={t('bo-settings-image-warning-sizes')} label={t('bo-settings-image-label-sizes')} />
        <div className={classes.datesContent}>
          <div className={classes.labelTitleDate}>{t('bo-event-dates')} </div>
          <DateList rows={rows} addNewDateScheduleHandler={addNewDateScheduleHandler} onDateChangeHandler={onDateChangeHandler} removeNewDateScheduleHandler={removeNewDateScheduleHandler} isDidabled={editDisabled} />
        </div>
        <div className={classes.datesContent}>
          <div className={classes.titleGeolocation}><div className={classes.labelTitleDate}>{t('bo-event-geolocation')}</div> <div className={classes.switchGeolocation}>{
            application.LocalizationEnabled ? 'Enabled' : 'Disabled'
          } <Switch disabled={editDisabled} checked={application.LocalizationEnabled} onChange={event => setApplication(prev => ({...prev, LocalizationEnabled: event.target.checked}))} color="primary" /> </div></div>
          <GeoLocationList rowsLocation={rowsLocation} addNewLocationScheduleHandler={addNewLocationScheduleHandler} onLocationChangeHandler={onLocationChangeHandler} removeNewLocationScheduleHandler={removeNewLocationScheduleHandler} isDidabled={editDisabled} />
        </div>
        {editDisabled ? 
          <Button type='button' variant="contained" classes={{ root: classes.button }} onClick={() => setEditDisabled(false)}>{t('bo-event-edit-btn-label')}</Button>
          :
          <Button type='button' variant="contained" classes={{ root: classes.button }} onClick={updateApplicationConfig}>{
            isLoading ? <CircularProgress size={24} className={classes.progress} /> : 
            t('bo-event-create-btn-label')
          }</Button>  
        }
        
      </Paper>
    </div>
  )
}

export default Events