const Styles = theme => ({
    root: {
        width: '80%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
    },
    paper:{
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        margin: "40px 0px",
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    contentInput:{
        display: 'block',
        marginBottom: '1rem',
        width: '50%'
    },
    datesContent:{
        display: 'block',
        marginBottom: '1rem',
        width: '100%'
    },
    
    labelTitle: {
        fontSize: '0.75rem',
        marginLeft: '14px',
        marginBottom: '0.5rem',
        opacity: '0.75'
    },
    labelTitleDate: {
        fontSize: '1.5rem',
        marginBottom: '0.5rem',
        opacity: '0.75',
        fontWeight: '600'
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A'
    },
    imageContent: {
        display: 'block',
        backgroundColor: '#dfdfdf',
        padding: '10px',
        position: 'relative',
        borderRadius: '10px',
        boxShadow: 'inset 0px 0px 16px 0px rgb(0 0 0 / 10%)',
        overflow: 'hidden',
        maxWidth: '300px',
        '& img': {
            display: 'block',
            objectFit: 'contain',
            maxHeight: '100%',
            maxWidth: '100%',
        },
        '& span': {
            backgroundColor: 'rgba(0,0,0,0.7)',
            position: 'absolute',
            top: '0px',
            left: '0px',
            height: '100%',
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            fontSize: '0.85rem',
            color: 'rgba(255,255,255)',
            transition: '0.2s 0.3s linear',
            opacity: 0,
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover span': {
            transition: '0.2s 0.3s linear',
            opacity: 1,
        },
    },
    aspectRatio:{
        fontSize: '0.7rem',
        color: '#cecece',
        display: 'block',
    },
    inputHidden: {
        display: 'block',
        opacity: 0,
        height: 0,
        width: 0,
    },
    titleGeolocation:{
        display: 'flex',
        width: '100%',
        justifyContent:'space-between',
        alignItems: 'center',
    },
    switchGeolocation: {
        fontSize: '1rem',
    }
})

export default Styles;