import React, { useEffect, useState } from 'react'
import AWS from 'aws-sdk';
import * as AuthService from '../../service/api/auth/';
import { GetAnonymousUser } from '../../service/api/video-stream';
import Amplify, {Storage} from 'aws-amplify';
import aws_export from '../../aws-exports'
import { currentUserSession, signin } from './photoService';
import {Styles} from './style';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import LoadingPopup from '../../components/Shared/LoadingPopup';
import calendarImg from '../../assets/images/calendar.png'
import seta from '../../assets/images/arrow-right.png'
import { useNavigate, useSearchParams } from 'react-router-dom';
import i18next from 'i18next';
import localforage from 'localforage';
import { useTranslation } from 'react-i18next';

Amplify.configure(aws_export);
const useStyles = makeStyles(Styles);

export const PhotoGallery = () => {
  const classes = useStyles();
  const [session, setSession] = useState();
  const [folders, setFolders] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasUser, setHasUser] = useState(false)
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const lang = navigator.language.split('-')[0]
    onChangeLang(lang)
    moment.locale(lang);
}, [])



const onChangeLang = async (lg) => {
    i18next.changeLanguage(lg);
    await localforage.setItem('lang', lg)

}

  const getAnonymousUser = async () => {
    try {
        const result = await GetAnonymousUser();
        return result
    } catch (error) {
        const errorCode = error.code;
        switch (errorCode) {
            case 'NetworkError':
                break;
            default:
                break;
        }
    }
  }

  // Get anonymous user
  const authAnonymousUser = async () => {
    try {
        let haveSession = await currentUserSession();
        if (!haveSession) {
            let { data } = await getAnonymousUser();
            let hasSession = await signin(data.getAnonymousUser.username, data.getAnonymousUser.password);
            setSession(hasSession)
            return
        }
        setSession(haveSession)
    } catch (error) {
        throw error
    }
  }

  const sortDates = (datesArray) => {
    const momentsArray = datesArray
      .map(dateStr => moment(dateStr, 'DD.MM.YYYY/', true)) // Parse com o formato 'DD.MM.YYYY/' e ignorar datas inválidas
      .filter(momentObj => momentObj.isValid()); // Filtrar datas válidas

    // Ordenar os objetos Moment
    momentsArray.sort((a, b) => a - b);

    // Converter os objetos Moment de volta para strings
    const sortedDatesArray = momentsArray.map(momentObj => momentObj.format('DD.MM.YYYY/'));
    return sortedDatesArray
  }

  async function listFolders() {
    try {
      const result = await Storage.list('', { level: 'public' });
      const folderNames = result?.map(item => item.key)?.filter(key => key.endsWith('/'));
      const dateSort = sortDates(folderNames)
      setFolders(dateSort)
    } catch (error) {
      console.error('Error listing folders:', error);
      return [];
    }
  }

  useEffect(() => {
    authAnonymousUser()
    const token = searchParams.get("from")
    if(token === 'eoapp') {
      setHasUser(true)
    }
  },[])

  useEffect(() => {
    listFolders()
  },[session])

  return (
    <>
    <div className={classes.root}>
      {
        folders ? hasUser ? <>
        <div className={classes.title}>{t('gallery-title')}</div>
        <div className={classes.datesContainer}>
          {folders.map(el => (<div className={classes.dateButton} onClick={() => navigate(`/photo-gallery/${el}`, { state: {key: el, token: searchParams.get("from") }, })} key={el}>
            <img src={calendarImg} alt='calendario' />
              {moment(el, 'DD.MM.YYYY/').format('dddd, DD MMMM YYYY')}
            <img src={seta} alt='seta' />
            </div>))}
        </div> </>: <div className={classes.noUser}>No User</div> :
        <div className={classes.loadingPop}><LoadingPopup /></div>
      }
    </div>
    </>
  )
}
