import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Assets from '../../../../assets';
import Footer from '../../../../components/VideoWall/Footer';
import { useVideowall } from '../../../../context/Videowall';
import { ServiceContext } from '../../../../context/services/service-context';
import { stopMaster } from '../master';
import { GivePoints } from '../../../../service/api/third-party';
import { useSearchParams } from 'react-router-dom';
import i18next from 'i18next';
import localforage from 'localforage';


const NextEvent = (props) => {



    const classes = useStyles();
    const { t } = useTranslation();
    const { VideoStream } = useContext(ServiceContext);
    const { setShowSchedule, userId } = useVideowall()
    const [nextEvent, setNextEvent] = useState(null)

  const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        if(userId) {
            GivePoints({input: {userId}})
        }
        const lang = navigator.language.split('-')[0]
        onChangeLang(lang)
    }, [])



    const onChangeLang = async (lg) => {
        i18next.changeLanguage(lg);
        await localforage.setItem('lang', lg)

    }


    useEffect(() => {
        const fetchEventSchedule = async () => {
            try {
                const schedules = await VideoStream.listEventSchedulesCloudfront()
                let dates = [];
                schedules.forEach(it => {
                    dates = [...dates, ...it.dates]
                });
                let schedulesSorted = dates.sort((a, b) => a.startTimestamp - b.startTimestamp);
                let scheduleFiltered = schedulesSorted.filter(date => date.startTimestamp >= new Date().getTime())
                let dataObject = {}
                scheduleFiltered?.forEach(it => {
                    const date = new Date(it.startTimestamp)
                    const key = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`
                    if (dataObject[key]) {
                        dataObject[key] = [...dataObject[key], it.startTimestamp]
                    } else {
                        dataObject[key] = [it.startTimestamp]
                    }
                })
                const objectValues = Object.values(dataObject);
                if (objectValues?.length) {
                    const next = objectValues[0]
                    setNextEvent(next[0])
                }
            } catch (error) {
                setNextEvent()
            }
        }
        stopMaster()
        fetchEventSchedule()
    }, [])


    return (
        <div className={classes.root}>
            {/* <div className={classes.header}>
                <h4>{t('videowall-app-next-event-title')}</h4>
                 <div className={classes.infoHeaderBox}>
                    {nextEvent && (
                        <div className={classes.infoHeaderBoxDate}>
                            <h3>{moment(nextEvent).format('DD/MM - HH:mm')}</h3>
                        </div>
                    )}
                    <span onClick={() => setShowSchedule(true)}>
                        {t('videowall-app-next-event-view-event')}
                    </span>
                </div>
            </div> */}
            <div className={classes.content}>
                <div className={classes.infoBox}>
                    <span>{t('experience')}</span>
                    <span>{t('videowall-app-next-event-info')}</span>
                </div>
                <div className={classes.dashBox}>
                    {/* <img src={Assets.Images.videowall_dashed_line} alt="" /> */}
                </div>
            </div>
            <Footer />
            {/* <p>userId: {userId}</p> */}
        </div>
    )
}
export default NextEvent


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '0px',
        flexDirection: 'column'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '90px',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))',
        '& h4': {
            color: '#FFF',
            margin: 0,
            font: 'normal normal bold 21px/21px RamaGothic',
            whiteSpace: 'pre-line',
            textAlign: 'center'
        },
    },
    infoHeaderBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '13px',
        '& span': {
            cursor: 'pointer',
            color: '#FFF',
            margin: '0 10px',
            font: 'normal normal normal 13px/13px RamaGothic',
            textDecoration: 'underline',
            minWidth: '80px'
        }
    },
    infoHeaderBoxDate: {
        borderRadius: '2px',
        backgroundColor: '#0A3655',
        '& h3': {
            color: '#FFF',
            margin: '0 10px',
            font: 'normal normal bold 18px/20px RamaGothic',
            minWidth: '120px',
            textAlign: 'center'
        }
    },
    content: {
        position: 'relative',
        height: '320px',
        background: '0% 0% no-repeat padding-box',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px 13px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
    },
    errorBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    infoBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        right: 0,
        left: 0,
        '& span': {
            width: '60%',
            font: 'normal normal normal 24px/25px OpenSans',
            color: '#e23172',
            fontWeight: '400',
            marginTop: '10px',
            fontFamily: 'RamaGothic',
            textAlign: 'center',
            '&:first-child': {
                fontFamily: 'RamaGothicSB',
                marginBottom: 16
            }
        }
    },
    dashBox: {
        height: '281px',
        width: '281px',
        display: 'block',
        borderRadius: '28px',
        margin: 'auto',
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='28' ry='28' stroke='%23e23172' stroke-width='5' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
    }
}))
