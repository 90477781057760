import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from 'react';

const Button = ({
    disabled,
    label,
    styles,
    onClick,
    active,
    ...props
}) => {

    const classes = useStyles();

    return (
        <Box className={active ? classes.root : classes.root2} onClick={() => {
            if (!disabled) {
                onClick()
            }
        } } style={styles}>
            {label}
        </Box>
    )
}

export default Button;



const useStyles = makeStyles({
    root: {
        alignItems: 'center',
        backgroundColor: '#E23172',
        borderRadius: '40px',
        color: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        font: 'normal normal normal 14px/18px RamaGothicSB',
        letterSpacing: '1px',
        height: '48px',
        justifyContent: 'center',
        margin: 'auto 8px',
        textAlign: 'center',
        textTransform: 'uppercase',
        width: '115px',

    },
    root2: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: '1px solid #A8A8A8',
        borderRadius: '40px',
        color: '#373737',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        font: 'normal normal normal 14px/18px RamaGothicSB',
        letterSpacing: '1px',
        height: '48px',
        justifyContent: 'center',
        margin: 'auto 8px',
        textAlign: 'center',
        textTransform: 'uppercase',
        width: '115px',

    }
});
