/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUser = /* GraphQL */ `
  query ListUser {
    listUser {
      items {
        sub
        username
        enabled
        status
        createAt
        given_name
        family_name
        name
        picture
        email
        groups
        __typename
      }
      __typename
    }
  }
`;
export const listGroup = /* GraphQL */ `
  query ListGroup {
    listGroup {
      items {
        groupName
        description
        userGroupId
        __typename
      }
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($input: GetUserInput) {
    getUser(input: $input) {
      sub
      username
      enabled
      status
      createAt
      given_name
      family_name
      name
      picture
      email
      groups
      __typename
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($input: GetChannelInput) {
    getChannel(input: $input) {
      errorMessage
      errorCode
      channel {
        name
        arn
        __typename
      }
      __typename
    }
  }
`;
export const getAnonymousUser = /* GraphQL */ `
  query GetAnonymousUser {
    getAnonymousUser {
      username
      password
      __typename
    }
  }
`;
export const getPositionContents = /* GraphQL */ `
  query GetPositionContents($input: GetPositionContentsInput) {
    getPositionContents(input: $input) {
      errorMessage
      errorCode
      positionContents {
        position
        startDisplayTimestamp
        endDisplayTimestamp
        arn
        name
        status
        __typename
      }
      __typename
    }
  }
`;
export const getQueueContents = /* GraphQL */ `
  query GetQueueContents($input: GetQueueContentsInput) {
    getQueueContents(input: $input) {
      errorMessage
      errorCode
      queueContents {
        position
        startDisplayTimestamp
        endDisplayTimestamp
        arn
        name
        status
        __typename
      }
      queueTotal
      __typename
    }
  }
`;
export const getEventStatus = /* GraphQL */ `
  query GetEventStatus {
    getEventStatus {
      errorMessage
      errorCode
      status
      __typename
    }
  }
`;
export const getStreamingUserCount = /* GraphQL */ `
  query GetStreamingUserCount {
    getStreamingUserCount {
      errorMessage
      errorCode
      count
      __typename
    }
  }
`;
export const listEventSchedulesCached = /* GraphQL */ `
  query ListEventSchedulesCached {
    listEventSchedulesCached {
      errorMessage
      errorCode
      items {
        id
        name
        description
        startTimestamp
        endTimestamp
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const getBOEventStatus = /* GraphQL */ `
  query GetBOEventStatus {
    getBOEventStatus {
      errorMessage
      errorCode
      eventStatus
      eventStreamingStatus
      __typename
    }
  }
`;
export const getRealAvailableChannelsCount = /* GraphQL */ `
  query GetRealAvailableChannelsCount {
    getRealAvailableChannelsCount {
      errorMessage
      errorCode
      availableCount
      totalCount
      __typename
    }
  }
`;
export const getAvailableChannelsCount = /* GraphQL */ `
  query GetAvailableChannelsCount {
    getAvailableChannelsCount {
      errorMessage
      errorCode
      availableCount
      totalCount
      __typename
    }
  }
`;
export const getS3SignedUrl = /* GraphQL */ `
  query GetS3SignedUrl($input: GetS3SignedUrlInput!) {
    getS3SignedUrl(input: $input) {
      signedUrl
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const getBOModule = /* GraphQL */ `
  query GetBOModule($id: ID!) {
    getBOModule(id: $id) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBOModules = /* GraphQL */ `
  query ListBOModules(
    $filter: ModelBOModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBOModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        enabled
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventNotificationSecret = /* GraphQL */ `
  query GetEventNotificationSecret($id: ID!) {
    getEventNotificationSecret(id: $id) {
      id
      used
      ttl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventNotificationSecrets = /* GraphQL */ `
  query ListEventNotificationSecrets(
    $id: ID
    $filter: ModelEventNotificationSecretFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventNotificationSecrets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        used
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventNotification = /* GraphQL */ `
  query GetEventNotification($project: String!, $id: ID!) {
    getEventNotification(project: $project, id: $id) {
      project
      id
      eventType
      username
      email
      secretARN
      cognitoUserPoolID
      cognitoAppClientID
      kmsKeyARN
      apiURL
      videowallUsername
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventNotifications = /* GraphQL */ `
  query ListEventNotifications(
    $project: String
    $id: ModelIDKeyConditionInput
    $filter: ModelEventNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventNotifications(
      project: $project
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        project
        id
        eventType
        username
        email
        secretARN
        cognitoUserPoolID
        cognitoAppClientID
        kmsKeyARN
        apiURL
        videowallUsername
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMediaGridContent = /* GraphQL */ `
  query GetMediaGridContent($PK: String!, $SK: String!) {
    getMediaGridContent(PK: $PK, SK: $SK) {
      PK
      SK
      status
      startDisplayTimestamp
      endDisplayTimestamp
      contentRef
      arn
      name
      disconnectTimestamp
      connectTimestamp
      updatedAt
      count
      creationTimestamp
      appearedTimestamp
      createdAt
      __typename
    }
  }
`;
export const listMediaGridContents = /* GraphQL */ `
  query ListMediaGridContents(
    $PK: String
    $SK: ModelStringKeyConditionInput
    $filter: ModelMediaGridContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMediaGridContents(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        status
        startDisplayTimestamp
        endDisplayTimestamp
        contentRef
        arn
        name
        disconnectTimestamp
        connectTimestamp
        updatedAt
        count
        creationTimestamp
        appearedTimestamp
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($PK: String!, $SK: String!) {
    getLog(PK: $PK, SK: $SK) {
      PK
      SK
      timestamp
      description
      reference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $PK: String
    $SK: ModelStringKeyConditionInput
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLogs(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        timestamp
        description
        reference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($PK: ConfigurationType!, $SK: String!) {
    getConfiguration(PK: $PK, SK: $SK) {
      PK
      SK
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConfigurations = /* GraphQL */ `
  query ListConfigurations(
    $PK: ConfigurationType
    $SK: ModelStringKeyConditionInput
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConfigurations(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        value
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contentByStatus = /* GraphQL */ `
  query ContentByStatus(
    $PK: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMediaGridContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByStatus(
      PK: $PK
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        SK
        status
        startDisplayTimestamp
        endDisplayTimestamp
        contentRef
        arn
        name
        disconnectTimestamp
        connectTimestamp
        updatedAt
        count
        creationTimestamp
        appearedTimestamp
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byReference = /* GraphQL */ `
  query ByReference(
    $reference: String
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byReference(
      reference: $reference
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        SK
        timestamp
        description
        reference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventSchedule = /* GraphQL */ `
  query GetEventSchedule($id: ID!) {
    getEventSchedule(id: $id) {
      id
      name
      description
      startTimestamp
      endTimestamp
      dates {
        startTimestamp
        endTimestamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventSchedules = /* GraphQL */ `
  query ListEventSchedules(
    $filter: ModelEventScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        startTimestamp
        endTimestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
