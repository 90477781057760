import AWS from 'aws-sdk';
import { Auth } from "aws-amplify";

/**
 * This file demonstrates the process of starting WebRTC streaming using a KVS Signaling Channel.
 */
const master = {
    signalingClient: null,
    peerConnectionByClientId: {},
    dataChannelByClientId: {},
    localStream: null,
    remoteStreams: [],
    peerConnectionStatsInterval: null,
};

const getResolution = async () => {
    let path = `${process.env.PUBLIC_URL}/config/stream_resolution.json`
    return await fetch(path)
        .then((response) => response.json())
        .then(json => {
            return json
        }).catch(error => {
            console.log(error);
        })

}

async function RenewSession() {
    await Auth.currentSession();
    let credentials = await Auth.currentCredentials();
    return credentials;
}

export async function startMaster(formValues, onStatsReport, onRemoteDataMessage, onPeerConnected, onPeerDisconnected) {
    // let master = {
    //     signalingClient: null,
    //     peerConnectionByClientId: {},
    //     dataChannelByClientId: {},
    //     localStream: null,
    //     remoteStreams: [],
    //     peerConnectionStatsInterval: null,
    // };


    // master.localView = localView;
    // master.remoteView = remoteView;

    //renew credentials if necessary (every request)
    let renewedCredentials = await RenewSession();

    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
        region: formValues.region,
        accessKeyId: renewedCredentials.accessKeyId,
        secretAccessKey: renewedCredentials.secretAccessKey,
	    sessionToken: renewedCredentials.sessionToken,
        endpoint: formValues.endpoint,
        correctClockSkew: true
    });

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
        .describeSignalingChannel({
            ChannelName: formValues.channelName,
        })
        .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    //console.log('[MASTER] Channel ARN: ', channelARN);

    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
        .getSignalingChannelEndpoint({
            ChannelARN: channelARN,
            SingleMasterChannelEndpointConfiguration: {
                Protocols: ['WSS', 'HTTPS'],
                Role: window.KVSWebRTC.Role.MASTER,
            },
        })
        .promise();
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
    }, {});
    //console.log('[MASTER] Endpoints: ', endpointsByProtocol);

    // Create Signaling Client
    master.signalingClient = new window.KVSWebRTC.SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        role: window.KVSWebRTC.Role.MASTER,
        region: formValues.region,
        credentials: {
            accessKeyId: renewedCredentials.accessKeyId,
            secretAccessKey: renewedCredentials.secretAccessKey,
            sessionToken: renewedCredentials.sessionToken,
        },
        systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    // Get ICE server configuration
    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
        region: formValues.region,
        accessKeyId: renewedCredentials.accessKeyId,
        secretAccessKey: renewedCredentials.secretAccessKey,
	    sessionToken: renewedCredentials.sessionToken,
        endpoint: endpointsByProtocol.HTTPS,
        correctClockSkew: true,
    });
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
        .getIceServerConfig({
            ChannelARN: channelARN,
        })
        .promise();
    const iceServers = [];
    if (!formValues.natTraversalDisabled && !formValues.forceTURN) {
        iceServers.push({ urls: `stun:stun.kinesisvideo.${formValues.region}.amazonaws.com:443` });
    }
    if (!formValues.natTraversalDisabled) {
        getIceServerConfigResponse.IceServerList.forEach(iceServer =>
            iceServers.push({
                urls: iceServer.Uris,
                username: iceServer.Username,
                credential: iceServer.Password,
            }),
        );
    }
    //console.log('[MASTER] ICE servers: ', iceServers);

    const configuration = {
        iceServers,
        iceTransportPolicy: formValues.forceTURN ? 'relay' : 'all',
    };

    let resolutionConfig = await getResolution();
    const resolution = resolutionConfig ? { width: { ideal: resolutionConfig.width }, height: { ideal: resolutionConfig.height }, facingMode: 'user' } : { width: { ideal: 640 }, height: { ideal: 480 }, facingMode: 'user' };
    //const resolution = formValues.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };

    const constraints = {
        video: formValues.sendVideo ? resolution : false,
        audio: formValues.sendAudio
    };

    // Get a stream from the webcam and display it in the local view. 
    // If no video/audio needed, no need to request for the sources. 
    // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
    if (formValues.sendVideo || formValues.sendAudio) {
        try {
            master.localStream = await navigator.mediaDevices.getUserMedia(constraints);
            //var video = document.getElementById("videoLocal");
            var video = formValues.videoRef.current
            video.srcObject = master.localStream;

            video.onloadedmetadata = function (e) {
                video.play();
            };
        } catch (e) {
            console.error('[MASTER] Could not find webcam');
        }
    }

    master.signalingClient.on('open', async () => {
        //console.log('[MASTER] Connected to signaling service');
        //console.log("VALUES: ", master);
    });

    master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
        //console.log('[MASTER] Received SDP offer from client: ' + remoteClientId);

        // Create a new peer connection using the offer from the given client
        const peerConnection = new RTCPeerConnection(configuration);
        master.peerConnectionByClientId[remoteClientId] = peerConnection;

        if (formValues.openDataChannel) {
            master.dataChannelByClientId[remoteClientId] = peerConnection.createDataChannel('kvsDataChannel');
            peerConnection.ondatachannel = event => {
                event.channel.onmessage = onRemoteDataMessage;
            };
        }

        // Poll for connection stats
        if (!master.peerConnectionStatsInterval) {
            master.peerConnectionStatsInterval = setInterval(() => peerConnection.getStats().then(onStatsReport), 1000);
        }


        /**
         * Check the peer connection status.
         */
        peerConnection.addEventListener('iceconnectionstatechange', event => {
            //console.log("[VIEWER][PEER CONNECTION] Status: " + peerConnection.iceConnectionState + " for client: " + remoteClientId)

             if (peerConnection.iceConnectionState === "connected") {
                //console.log("[MASTER][PEER CONNECTION] CONNECTED: " + JSON.stringify(event) + " for client: " + remoteClientId)

                //if videowall
                if (remoteClientId.startsWith('videowall-'))
                    onPeerConnected();
             }
            if (peerConnection.iceConnectionState === "disconnected") {
                //console.log("[MASTER][PEER CONNECTION] DISCONNECTED: " + JSON.stringify(event) + " for client: " + remoteClientId)

                //stopViewer(viewer);
                //remove(position, formValues.channelName)

                //if videowall
                if (remoteClientId.startsWith('videowall-'))
                    onPeerDisconnected();
            }

            if (peerConnection.iceConnectionState === "failed") {
                //console.log("[MASTER][PEER CONNECTIION] FAILED: " + JSON.stringify(event) + " for client: " + remoteClientId)
                peerConnection.restartIce();
            }

        });
 


        // Send any ICE candidates to the other peer
        peerConnection.addEventListener('icecandidate', ({ candidate }) => {
            if (candidate) {
                //console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId);

                // When trickle ICE is enabled, send the ICE candidates as they are generated.
                if (formValues.useTrickleICE) {
                    //console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
                    master.signalingClient.sendIceCandidate(candidate, remoteClientId);
                }
            } else {
                //console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);

                // When trickle ICE is disabled, send the answer now that all the ICE candidates have ben generated.
                if (!formValues.useTrickleICE) {
                    //console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
                    master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
                }
            }
        });

        // As remote tracks are received, add them to the remote view
        peerConnection.addEventListener('track', event => {
            //console.log('[MASTER] Received remote track from client: ' + remoteClientId);
            var video = document.getElementById("videoRemote");

            if (video.srcObject) {
                return;
            }
            video.srcObject = event.streams[0];
            video.onloadedmetadata = function (e) {
                video.play();
            };
        });


        // If there's no video/audio, master.localStream will be null. So, we should skip adding the tracks from it.
        if (master.localStream) {
            master.localStream.getTracks().forEach(track => peerConnection.addTrack(track, master.localStream));
        }
        await peerConnection.setRemoteDescription(offer);

        // Create an SDP answer to send back to the client
        //console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);
        await peerConnection.setLocalDescription(
            await peerConnection.createAnswer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
            }),
        );

        // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
        if (formValues.useTrickleICE) {
            //console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
            master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
        }
        //console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
    });

    master.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
        //console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId);

        // Add the ICE candidate received from the client to the peer connection
        const peerConnection = master.peerConnectionByClientId[remoteClientId];
        peerConnection.addIceCandidate(candidate);
    });

    master.signalingClient.on('close', () => {
        //console.log('[MASTER] Disconnected from signaling channel');
    });

    master.signalingClient.on('error', () => {
        console.error('[MASTER] Signaling client error');
        return new Promise((resolve, reject) => {
            reject(null)
        })
    });

    //console.log('[MASTER] Starting master connection');
    master.signalingClient.open();

    return new Promise((resolve, reject) => {
        resolve(master)
    })
}





export function stopMaster() {

    try {
        //console.log('[MASTER] Stopping master connection');
        if (master.signalingClient) {
            master.signalingClient.close();
            master.signalingClient = null;
        }

        Object.keys(master.peerConnectionByClientId).forEach(clientId => {
            master.peerConnectionByClientId[clientId].close();
        });
        master.peerConnectionByClientId = [];

        if (master.localStream) {
            master.localStream.getTracks().forEach(track => track.stop());
            master.localStream = null;
        }

        master.remoteStreams.forEach(remoteStream => remoteStream.getTracks().forEach(track => track.stop()));
        master.remoteStreams = [];

        if (master.peerConnectionStatsInterval) {
            clearInterval(master.peerConnectionStatsInterval);
            master.peerConnectionStatsInterval = null;
        }

        // if (master.localView) {
        //     master.localView.srcObject = null;
        // }

        // if (master.remoteView) {
        //     master.remoteView.srcObject = null;
        // }

        if (master.dataChannelByClientId) {
            master.dataChannelByClientId = {};
        }
    } catch (error) {
        //console.log(error)
    }
   
}

function sendMasterMessage(message) {
    Object.keys(master.dataChannelByClientId).forEach(clientId => {
        try {
            master.dataChannelByClientId[clientId].send(message);
        } catch (e) {
            console.error('[MASTER] Send DataChannel: ', e.toString());
        }
    });
}
