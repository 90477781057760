import React, { useContext, useEffect, useState } from 'react'
import Styles from "./style";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { ServiceContext } from '../../../context/services/service-context';
import { useNotifications } from '../../../components/Shared/NotificationSnackbar';

const useStyles = makeStyles(Styles);

const ThirdParty = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { triggerNotification } = useNotifications();
  const { ThirdParty } = useContext(ServiceContext);
  const [ dataList, setDataList ] = useState([]);
  const [open, setOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState();

  const columns = [
    { field: 'cognitoUserPoolID', headerName: 'Cognito ID', flex: 0.6 },
    { field: 'project', headerName: 'Project', flex: 0.5 },
    { field: 'username', headerName: 'Username', flex: 0.8  },
    { field: 'videowallUsername', headerName: 'Videowall Username', flex: 0.8  },
    { field: 'cognitoAppClientID', headerName: 'Cognito App Client ID', flex: 0.6 },
    { field: 'secretARN', headerName: 'Secret ARN', flex: 0.5 },
    { field: 'kmsKeyARN', headerName: 'Kms Key ARN', flex: 0.5 },
    { field: 'apiURL', headerName: 'AppSync URL', flex: 0.5 },
    { field: 'edit', headerName: '', flex: 0.6, sortable: false, renderCell: ({row}) => { return <>
      <IconButton onClick={()=> {goToForm(row)}} ><Edit fontSize="small" /></IconButton>
      <IconButton onClick={()=> {deleteUserAPI(row)}} ><Delete fontSize="small" color='error' /></IconButton>
    </>}}
  ];

  useEffect(() => {
    const listNotifications =  async () => {
      const result = await ThirdParty.listEventNotifications()
      if(result) normalizeDataRow(result?.items)
    }

    const normalizeDataRow = (data) => {
      const dataListToMap = [...data];
      const mappedDataList = dataListToMap.map((itemData) => (
        {
          id: itemData.id,
          cognitoUserPoolID: itemData.cognitoUserPoolID,
          project: itemData.project,
          username: itemData.username,
          videowallUsername: itemData.videowallUsername,
          cognitoAppClientID: itemData.cognitoAppClientID,
          secretARN: itemData.secretARN,
          kmsKeyARN: itemData.kmsKeyARN,
          apiURL: itemData.apiURL,
        }
      ))
      setDataList(mappedDataList)
    }

    listNotifications()

  }, [open])

  const goToForm = (rowItem) => {
    navigate('detail', { state: {userApi: rowItem}})
  }

  const deleteUserAPI = (rowItem) => {
    setUserToDelete(rowItem)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCloseDelete = async () => {
    const userToDeleted = {input: {
      project: userToDelete.project,
      id: userToDelete.id,
      videowallUsername: userToDelete.videowallUsername,
      secretARN: userToDelete.secretARN,
      kmsKeyARN: userToDelete.kmsKeyARN
    }}
    try {
      const result = await ThirdParty.deleteCustomEventNotification(userToDeleted)
      if(result) {
        triggerNotification("success", t("notifications-success-title"))
      }
    } catch (error) {
      console.log(error)
    }
    setOpen(false)
  }


  return (
    <div className={classes.root}>
      <Box className={classes.boxHeader}>
        <div className={classes.title}>{t('thirdparty-page-title')}</div>
        <Box className={classes.boxButton}>
            <Button
                startIcon={<Add />}
                variant="contained"
                onClick={() => navigate("detail")}
            >
                {t("create")}
            </Button>
        </Box>
      </Box>
      {dataList && dataList.length > 0 ?
      <div className={classes.grid}>
        <DataGrid 
          rows={dataList}
          columns={columns}
          pageSize={5}
          disableSelectionOnClick
          disableColumnMenu
          autoHeight
          className={classes.table}
        />
      </div>
      :null}
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialogColors}
      >
        <DialogTitle id="alert-dialog-title">{t('user-delete-title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('user-delete-text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleCloseDelete} autoFocus>
          {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ThirdParty
