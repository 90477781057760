import axios from 'axios';
import { INSTANCE } from '../../../layout/config/instance.config';

const AxiosInstance = axios.create({
  baseURL: INSTANCE.url,
  headers: {
    'x-api-key': INSTANCE.xApi
  }
});

export default AxiosInstance;