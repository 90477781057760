import React from 'react';
import { makeStyles } from '@material-ui/core';
import Assets from '../../../assets';


const ErrorPopup = ({
    info,
    onRefresh
}) => {

    const classes = useStyles();

    return (
        <div className={classes.root} onClick={() => onRefresh()}>
            <span>{info}</span>
            <div>
                <img src={Assets.Images.videowall_ico_refresh} alt="" />
            </div>
        </div>
    )
}

export default ErrorPopup


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        margin: '0 12px',
        height: 164,
        padding: 10,
        backgroundColor: '#5e2750',
        position: 'relative',
        '& span': {
            font: 'normal normal normal 18px/20px OpenSans',
            textAlign: 'center',
            color: '#FFF',
            paddingBottom: '16px',
            maxWidth: '270px'
        }
    },
    buttonRefresh: {
        width: 48,
        height: 48,
        borderRadius: '50%',
        background: '#F50000 0% 0% no-repeat padding-box',
        position: 'absolute',
        bottom: '-24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF'
    }
}))
