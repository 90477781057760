import React, { useContext, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { TextFields, Subject, Today, Delete, Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';

import { useNavBar } from '../../index';
import { ServiceContext } from '../../../../../../context/services/service-context';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const ScheduleCreateView = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { goToTab } = useNavBar();
    const { VideoStream } = useContext(ServiceContext);
    const [dates, setDates] = useState([])
    const [rows, setRows] = useState({ renders: null })


    const createScheduleFormik = useFormik({
        initialValues: {
            name: '',
            description: '',
            dates: []
        },
        onSubmit: async (values) => {
            try {
                values.id = uuidv4();
                let datesArray = []

                dates.forEach(element => {
                    datesArray.push({
                        startTimestamp: new Date(element.startTimestamp).getTime(),
                        endTimestamp: new Date(element.endTimestamp).getTime()
                    })
                });
                values.dates = datesArray;

                let result = await VideoStream.createEventSchedule(values)
                if (result) {
                    goToTab(0)
                }
            } catch (error) {
                throw error
            }
        },
    });

    const onDateChangeHandler = (event) => {
        let id = event.target.id;
        let name = event.target.name;
        let value = event.target.value;

        let exists = dates.find(item => item.id === id);
        if (exists) {
            dates.filter(item => {
                if (item.id === id) {
                    item[name] = value;
                }
            })
        } else {
            let newDate = { id: id };
            newDate[name] = value;
            dates.push(newDate);
        }
        console.log(dates)
        setDates(dates);
    }

    function addNewDateScheduleHandler() {
        let array = Array.isArray(rows.renders) ? rows.renders : []
        array.push({ id: `data-group-${array.length + 1}` })
        setRows({ renders: array });
    }

    function removeNewDateScheduleHandler(id) {
        let array = rows.renders;
        array = array.filter(item => item.id !== id);
        setRows({ renders: array });
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs>
                <Paper elevation={0} className={classes.paper}>
                    <form onSubmit={createScheduleFormik.handleSubmit} className={classes.form}>
                        <TextField
                            id="name"
                            type='text'
                            label={t('bo-schedule-create-input1-label')}
                            placeholder={t('bo-schedule-create-input1-label')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <TextFields className={classes.icons} />
                            }}
                            onChange={createScheduleFormik.handleChange}
                            required
                        />
                        <TextField
                            id="description"
                            type='text'
                            label={t('bo-schedule-create-input2-label')}
                            placeholder={t('bo-schedule-create-input2-label')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <Subject className={classes.icons} />
                            }}
                            onChange={createScheduleFormik.handleChange}
                            required
                            rows={8}
                            multiline
                            classes={{
                                root: classes.textArea,
                            }}
                        />

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '20px 0px' }}>
                            <div style={{ flex: 1, display: 'flex', fontWeight: 600, fontSize: "18px", color: 'rgba(0,0,0,0.54)', }}>{t('bo-schedule-create-input5-label')}</div>
                        </div>



                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 1, display: 'flex', fontWeight: 400, fontSize: "12px", color: 'rgba(0,0,0,0.54)', }}>{t('bo-schedule-create-input3-label')}</div>
                            <div style={{ width: '20px' }} />
                            <div style={{ flex: 1, display: 'flex', fontWeight: 400, fontSize: "12px", color: 'rgba(0,0,0,0.54)' }}>{t('bo-schedule-create-input4-label')}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                id="data-group-0"
                                name="startTimestamp"
                                type='datetime-local'
                                variant="outlined"
                                placeholder={t('bo-schedule-create-input2-label')}
                                InputProps={{
                                    startAdornment: <Today className={classes.icons} />
                                }}
                                // onChange={createScheduleFormik.handleChange}
                                onChange={onDateChangeHandler}
                                style={{ flex: 1 }}
                                required
                            />
                            <div style={{ width: '20px' }} />
                            <TextField
                                id="data-group-0"
                                name="endTimestamp"
                                type='datetime-local'
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <Today className={classes.icons} />
                                }}
                                style={{ flex: 1 }}
                                // onChange={createScheduleFormik.handleChange}
                                onChange={onDateChangeHandler}
                                required
                            />
                        </div>

                        {
                            rows.renders ?
                                rows.renders.length > 0 ?
                                    rows.renders.map((prop) =>
                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }} key={prop.id}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} >
                                                <div style={{ flex: 1, display: 'flex', fontWeight: 400, fontSize: "12px", color: 'rgba(0,0,0,0.54)', }}>{t('bo-schedule-create-input3-label')}</div>
                                                <div style={{ width: '20px' }} />
                                                <div style={{ flex: 1, display: 'flex', fontWeight: 400, fontSize: "12px", color: 'rgba(0,0,0,0.54)' }}>{t('bo-schedule-create-input4-label')}</div>
                                                <div style={{ width: '39px' }} />
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <TextField
                                                    id={prop.id}
                                                    // id={}
                                                    name="startTimestamp"
                                                    type='datetime-local'
                                                    variant="outlined"
                                                    placeholder={t('bo-schedule-create-input2-label')}
                                                    InputProps={{
                                                        startAdornment: <Today className={classes.icons} />
                                                    }}
                                                    onChange={onDateChangeHandler}
                                                    style={{ flex: 1 }}
                                                    required
                                                />
                                                <div style={{ width: '20px' }} />
                                                <TextField
                                                    id={prop.id}
                                                    name="endTimestamp"
                                                    type='datetime-local'
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <Today className={classes.icons} />
                                                    }}
                                                    style={{ flex: 1 }}
                                                    onChange={onDateChangeHandler}
                                                    required
                                                />
                                                <div style={{ width: '20px' }} />
                                                <div onClick={() => removeNewDateScheduleHandler(prop.id)} style={{ cursor: 'pointer', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', backgroundColor: '#064873' }}>
                                                    <Delete style={{ color: '#ffffff', fontSize: "19px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : null
                                : null
                        }



                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <div onClick={() => addNewDateScheduleHandler()} style={{ cursor: 'pointer', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', backgroundColor: '#064873' }}>
                                <Add style={{ color: '#ffffff', fontSize: "19px" }} />
                            </div>
                        </div>


                        {/* <Button onClick={() => addNewDateScheduleHandler()} variant="contained" disableElevation classes={{ root: classes.button }}>Add</Button> */}

                        <Button type='submit' variant="contained" disableElevation disabled={createScheduleFormik.isSubmitting} classes={{ root: classes.button }}>{
                            createScheduleFormik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('bo-schedule-create-btn-label')
                        }</Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ScheduleCreateView;