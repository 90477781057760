import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Styles from './style';
import { TextField } from '@material-ui/core'
import { Delete, Today } from '@material-ui/icons'
import moment from 'moment';
import { useNotifications } from '../../../../Shared/NotificationSnackbar';

const useStyles = makeStyles(Styles);

const DateItem = ({item, onDateChangeHandler, removeNewDateScheduleHandler, firstItem, isDidabled}) => {

    const { t } = useTranslation();
    const classes = useStyles();

    const { triggerNotification } = useNotifications();
    
    const [startDate, setStartDate] = useState(item.startTime ?? '');
    const [startError, setStartError] = useState(false);
    const [endDate, setEndDate] = useState(item.endTime ?? '');
    const [endError, setEndError] = useState(false);

    const onStartDateChangeHandler = (event) => {
        const dateNow = new Date()
        if (moment(event.target.value).isBefore(dateNow)){
            setStartError(true);
            triggerNotification('warning', t('error-datenow-event'))
            return false
        }
            
        if(endDate && moment(endDate).isBefore(event.target.value)) {
            setStartError(true);
            triggerNotification('warning', t('error-start-date-event'))
        }else{
            setStartDate(moment(event.target.value).valueOf());
            setStartError(false);
        }
        onDateChangeHandler(event);
    }
    
    const onEndDateChangeHandler = (event) => {
        const dateNow = new Date()
        if (moment(event.target.value).isBefore(dateNow)){
            setStartError(true);
            triggerNotification('warning', t('error-datenow-end-event'))
            return false
        }
        if(startDate && moment(event.target.value).isBefore(startDate)) {
            setEndError(true)
            triggerNotification('warning', t('error-end-date-event'))
        }else{
            setEndDate(moment(event.target.value).valueOf());
            setEndError(false);
        }
        onDateChangeHandler(event);
    }

  return (
    <div className={classes.datesContent}>
        <div className={classes.dateInput}>
            <TextField
                id={item.id}
                name="startTime"
                type='datetime-local'
                variant="outlined"
                placeholder={t('bo-schedule-create-input2-label')}
                label={t('bo-schedule-create-input3-label')}
                InputProps={{
                    startAdornment: <Today className={classes.icons} />
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={onStartDateChangeHandler}
                value={moment(startDate).format('yyyy-MM-DDThh:mm')}
                style={{ flex: 1 }}
                error={startError}
                required
                disabled={isDidabled}
            />
        </div>
        <div className={classes.dateInput}>
            <TextField
                id={item.id}
                name="endTime"
                type='datetime-local'
                variant="outlined"
                label={t('bo-schedule-create-input4-label')}
                InputProps={{
                    startAdornment: <Today className={classes.icons} />
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                value={moment(endDate).format('yyyy-MM-DDThh:mm')}
                style={{ flex: 1 }}
                onChange={onEndDateChangeHandler}
                error={endError}
                required
                disabled={isDidabled}
            />
        </div>
        {firstItem !== 0 && !isDidabled && <div onClick={() => removeNewDateScheduleHandler(item.id)} className={classes.deleteBtn}>
            <Delete style={{ color: '#ffffff', fontSize: "19px" }} />
        </div>}
    </div>
  )
}

export default DateItem