import { API, graphqlOperation } from "aws-amplify";
import { createCustomEventNotification, deleteCustomEventNotification, givePoints, updateCustomEventNotification } from "../../../graphql/mutations";
import { listEventNotifications } from "../../../graphql/queries";

export const ListEventNotifications = async () => {
    return await API.graphql(graphqlOperation(listEventNotifications));
}

export const CreateCustomEventNotification = async (data) => {
    return await API.graphql(graphqlOperation(createCustomEventNotification, data));
}
export const UpdateCustomEventNotification = async (data) => {
    return await API.graphql(graphqlOperation(updateCustomEventNotification, data));
}
export const DeleteCustomEventNotification = async (data) => {
    return await API.graphql(graphqlOperation(deleteCustomEventNotification, data));
}
export const GivePoints = async (data) => {
    return await API.graphql(graphqlOperation(givePoints, data));
}
