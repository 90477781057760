import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Styles } from './style';
import Amplify, {Storage} from 'aws-amplify';
import aws_export from '../../aws-exports'
import { GetAnonymousUser } from '../../service/api/video-stream';
import { currentUserSession, signin } from './photoService';
import LoadingPopup from '../../components/Shared/LoadingPopup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(Styles);
Amplify.configure(aws_export);

export const Gallery = () => {
    const classes = useStyles();
    const { state } = useLocation();
    const [photosShow, setPhotosShow] = useState()
    const navigate = useNavigate();
    const { t } = useTranslation();

  const [session, setSession] = useState();

    const getAnonymousUser = async () => {
        try {
            const result = await GetAnonymousUser();
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    break;
                default:
                    break;
            }
        }
      }

      // Get anonymous user
      const authAnonymousUser = async () => {
        try {
            let haveSession = await currentUserSession();
            if (!haveSession) {
                let { data } = await getAnonymousUser();
                let hasSession = await signin(data.getAnonymousUser.username, data.getAnonymousUser.password);
                setSession(hasSession)
                return
            }
            setSession(haveSession)
        } catch (error) {
            throw error
        }
      }

    const getItems = async (folderName) => {
        const photos = await Storage.list(folderName, { level: 'public' });

        // Recupera cada arquivo individualmente
        const files = await Promise.all(photos.map(async photo => {
          try {
            // Usa o método get para obter o arquivo individual
            const file = await Storage.get(photo.key, { level: 'public' });
            console.log(file)
            return {
              name: photo.key, // Extrai apenas o nome do arquivo
              url: file // A URL do arquivo é diretamente o conteúdo retornado pelo método get
            };
          } catch (error) {
            console.error('Error getting photo:', photo.key, error);
            return null;
          }
        }));

        const validFiles = files.filter(file => file !== null);
        // console.log(validFiles);
        return validFiles;
      }


    async function listPhotosInFolders(folder) {
        try {
            const photoObject = {};
            try {
                const validUrl = await getItems(folder)
                photoObject[folder] = validUrl;
                setPhotosShow(validUrl)
            } catch (error) {
                console.error('Error listing photos in folder:', folder, error);
                photoObject[folder] = [];
            }

            return photoObject;
        } catch (error) {
            console.error('Error listing photos:', error);
            return {};
        }
      }

      useEffect(() => {
        authAnonymousUser()
      },[])


    useEffect(() => {
        if(state?.key){
            listPhotosInFolders(state?.key)
        }
    }, [session])

  return (
    <div className={classes.root}>
        {photosShow?.length > 1 ? <div className={classes.galleryContainer}>
            {photosShow.map((el, index) => {
                if(index !== 0) {
                    return (<div key={el.name + index} onClick={() => navigate(`/photo-gallery/photo`, {state: {key: state.key, from: state.from, photo: el.url, nameKey: el.name}})} className={classes.imageContainer}><img className={classes.imageSize} src={el.url} alt={el.name} /></div>)
                }
                return null
            })}
            <div className={classes.backButton} onClick={() => navigate('/photo-gallery?from='+state.token)}>{t('gallery-backlist')}</div>
        </div>: photosShow?.length > 0 ? <><div className={classes.noUser}>{t('no-photos')}</div> <div className={classes.backButton} onClick={() => navigate('/photo-gallery?from='+state.token)}>{t('gallery-backlist')}</div></> : <div className={classes.loadingPop}><LoadingPopup /></div> }
    </div>
  )
}
