import Image from "../../../assets/images/cycloid_pattern.png";
import RIR from "../../../assets/images/rir_background.png";

const Styles = theme => ({
    root: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000000'
    },
    body: {
        width: "100%",
        height: "100%",
        display: "flex",
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#efdf00',
        // background: 'linear-gradient(top, #EEDF17 0%, #EEDF17 10%, #EBE6B3 45%, #EAE9E9 87%)'
    },
    content:{
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        flexWrap: "nowrap",
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        zIndex: 1000,
        padding: '1.7%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'space-between',
        width: '100%',
        maxHeight: '12.5%',
    },
    imgLeft:{
        display: 'block',
        width: '12%',
        // height: 'auto'
    },
    imgRight:{
        display: 'block',
        width: '16%',
        height: 'auto',
    },
    feedsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: '100',
        width: '100%',
        margin: 'auto 0',
        '& div': {
            backgroundColor: 'rgba(30, 30, 30, 0)',
            display: 'block',
            position:'relative',
            width: '19%',
            paddingBottom : '19%',
            margin: '0 0.9rem 1.5rem',
            // '&:nth-child(even)':{
            //     margin: '1.5rem 0.9rem 0'
            // }
        },

        "& div.qrcode-text": {
            backgroundColor: "transparent",
            bottom: "-35px",
            display: "block",
            fontFamily: 'RamaGothicSB',
            fontSize: 44,
            height: 50,
            lineHeight: 1,
            margin:"auto 0",
            padding: 0,
            position: "absolute",
            right: "9.7%",
            textAlign: "center"
        },
        "& div.overlay-img": {
            backgroundColor: "transparent",
            bottom: "auto",
            display: "block",
            height: "100%",
            left: 0,
            margin:"auto",
            padding: 0,
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: 999,
            "& img": {
                height: "100%",
                objectFit: "contain",
                width: "100%"
            }
        }

    },
    qrcodeBox:{
        position: 'relative',
        overflow: 'hidden',
        '& img': {
            width: "calc(100% - 20px)",
            height: "calc(100% - 20px)",
            objectFit: 'contain',
            position: 'absolute',
            top: '10px',
            left: '10px',
        }
    },


    backgroundCover: {
        display: 'flex',
        // backgroundImage: `url(${RIR})`,
        // backgroundRepeat: 'repeat',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    overlay: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: "#FFFFFF",
        fontSize: "30px",
        fontWeight: 'bold',
        zIndex: '300',
    },
    watermark: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '10px',
        position: 'absolute',
        bottom: '15px',
        right: '15px',
        // backgroundColor: '#ffffff',
        zIndex: '99999999',
        '& img': {
            width: '15px',
            height: '15px',
            marginRight: '5px'
        },
        '& p': {
            margin: 0,
            padding: 0,
            color: 'rgba(0,0,0,0.4)'
        },
    },
    connectionPoint: {
        width: "10px",
        height: "10px",
        position: 'absolute',
        bottom: 0,
        right: 0,
        borderRadius: 0,
        backgroundColor: '#ff0000',
        zIndex: 99,
    },
    textRight: {
        color: '#FFFFFF',
        fontSize: '2rem',
        textAlign: 'right',
        fontFamily: 'OpenSans',
    },
    textMiddle: {
        color: 'black',
        fontSize: 50,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '2px',
        fontFamily: 'RamaGothic',
        left: 0,
        right: 0,
        marginTop: "2%",
        position: 'absolute',
    },
    textFooter: {
        display: 'block',
        color: '#FFFFFF',
        fontSize: '1rem',
        textAlign: 'center',
        fontFamily: 'OpenSans',
        margin: 'auto auto 0',
        position: 'relative',
        '& img': {
            maxWidth: '456px',
        }
    },
})

export default Styles;
