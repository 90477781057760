import { Dialog, makeStyles } from '@material-ui/core';
import { letterSpacing, textAlign } from '@mui/system';
import React from 'react';
import Assets from '../../../assets';
import Button from '../Button';


const LANGS = [
    { id: 'en', name: 'English' },
    { id: 'pt', name: 'Português' }
]


const LanguageOptions = ({
    onSelect,
    props
}) => {

    const classes = useStyles();

    const handleSelect = (id) => {
        onSelect(id)
    }


    return (
        <Dialog fullScreen open={true} className={classes.dialog} /*TransitionComponent={Transition}*/>
            <div className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.logoHeader}>
                        <img src={Assets.Images.vdf_logo} alt="" />
                    </div>
                    <div className={classes.eventName}>{props.eventName}</div>
                    <div className={classes.eventSubtitle}>Live Stream</div>
                </div>
                <div className={classes.buttonBox}>
                    {LANGS.map(it => (
                        <Button key={it.id} label={it.name} onClick={() => handleSelect(it.id)} styles={{ backgroundColor: '#064873', color: '#ffffff', padding: '4% 0 4%', width: '38%', boxSizing: 'border-box'}} />
                    ))}
                </div>
                <div className={classes.footerBox}>
                    <span>© Innowave {new Date().getFullYear()}</span>
                </div>
            </div>
        </Dialog>
    )

}

export default LanguageOptions;


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#EEDF17',
        background: 'linear-gradient(top, #EEDF17 0%, #EEDF17 10%, #EBE6B3 45%, #EAE9E9 87%)',
        '& .MuiBackdrop-root': {

            opacity: '0 !important'

        }
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: "100%",
        height: '100%',
		//backgroundImage: `url(${Assets.Images.videowall_background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '15%',
        width: '100%',
    },
    header: {
		display: 'flex',
		margin: '0 auto',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    logoHeader: {
        display: 'block',
        margin: '0 auto',
        width: '56%',
        '& img': {
            width: '100%',
            display: 'block',
            objectFit: 'contain'
        }
    },
	eventName:{
		color: '#FFF',
		fontFamily: 'OpenSans',
		fontSize: '3.8rem',
		fontWeight: 'bold',
		lineHeight: 0.94,
        marginTop: '9%',
		textAlign: 'center',
		textTransform: 'uppercase',
		letterSpacing: '3px',
        maxWidth: '90%',
	},
	eventSubtitle: {
		color: '#FFF',
		fontFamily: 'OpenSans',
		fontSize: '1.4rem',
		lineHeight: 1,
		textAlign: 'center',
        marginTop: '9%',
	},
    titleBox: {
        fontSize: '36px',
        color: 'white',
        textAlign: 'center',
        margin: '22px auto 22px'
    },
    poweredBox: {

    },
    footerBox: {
        padding: '6% 0',
        '& span': {
            font: 'normal normal normal 0.7rem OpenSans',
            margin: 0,
            color: '#FFF',
        }
    }
}))
