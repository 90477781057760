import { Grid, Switch, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';


const CustomSwitch = withStyles((theme) => ({
    root: {
        boxSizing: 'border-box',
        height: 54,
        width: 74,
        padding: '10px'
    },
    switchBase: {
        boxSizing: 'border-box',
        padding: 0,
        height: 34,
        top: 10,
        left: 12,
        '&$checked': {
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#34C759',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#34C759'
        },
    },
    thumb: {
        width: 28,
        height: 28,
    },
    track: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 34/2,
        backgroundColor: 'rgba(120, 120, 128, 0.16)',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
        height: 34,
        width: 51,
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});



export default function Toogle({
    on,
    disabled,
    onChange
}) {

    const classes = useStyles();
    const [checked, setChecked] = useState(false);


    useEffect(() => {
        setChecked(on);
    }, [on])

    return (

        <Grid  container alignItems="center" spacing={1} style={{ width: '100%', justifyContent: 'center', }}>

             <Grid item style={{ font: '14px RamaGothic', color: '#FFF' }} onClick={() => onChange(false)}>
                <div className={classes.button} style={{backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                    <span>OFF</span>
                </div>
            </Grid>
            <Grid item>
                <CustomSwitch checked={checked} name="checked" onChange={event => onChange(event.target.checked)} />
            </Grid>
            <Grid item >
                <div className={classes.button}
                    style={{
                        borderColor: on ? '#E23172' : '#FFFFFF',
                        backgroundColor: on ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.3)'
                    }}
                    onClick={() => onChange(true)}>
                    <span style={{ color: on ? '#E23172' : '#FFFFFF' }}>ON</span>
                </div>
            </Grid>
        </Grid>
    );
}


const useStyles = makeStyles(theme => ({
    button: {
        width: '57px',
        height: '31px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '14px',
        boxSizing: 'border-box',
        border: 'solid 1px #FFF',
        '& span': {
            font: '14px RamaGothic'
        }
    }
}))
