import React, { useEffect, useState, useRef, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Add } from '@material-ui/icons';
import Styles from './style';
import { startViewer, stopViewer } from './viewer';
import Assets from '../../../assets';

const useStyles = makeStyles(Styles);


const DEBUG = false;

const ChannelFeed = ({
    channelRef, id, debugChannel, small, remove, extend, transform, width, height, margin, autoStart, kick, origin
}) => {

    const classes = useStyles();

    const viewerRef = useRef(null);
    const videoRef = useRef(null);
    const compRef = useRef({ position: id, start: start, reset: reset, stop: stop, getPosition: getPosition, getChannel: getChannel, channel: '' });

    const [started, setStarted] = useState(false);
    const [channel, setChannel] = useState(null)

    useEffect(() => {
        channelRef(compRef.current);
    }, []);

    useEffect(() => {
        if (autoStart) {
            start(autoStart.position, autoStart.name, autoStart.credentials, autoStart.origin, "QUEUE");
        }
    }, [videoRef])

    
    const onPressRemoveHandler = async () => {
        if (kick) {
            await kick();
        } else if (remove) {
            await stopViewer(viewerRef.current);
            remove(compRef.current.position,compRef.current.channel)
            setStarted(false);
        }
    }

    const onPressExtendHandler = async () => {
        if (extend) {
            await extend(compRef.current.position);
        }
    }

    async function start(id, channel, credencials, origin, type) {
        await connectViewer(id, channel, credencials, origin, type);
        setStarted(true);
        // setChannel(channel);
        compRef.current.channel = channel;
    }

    async function reset(id, channel, credentials, origin, type) {
        compRef.current.channel = channel;
        // setChannel(channel);
        if (viewerRef.current) {
            await connectViewer(id, channel, credentials, origin, type, viewerRef.current);
        } else {
            await connectViewer(id, channel, credentials, origin, type);
        }
        setStarted(true);
    }

    async function stop() {
        await stopViewer(viewerRef.current);
        viewerRef.current = null;
        setStarted(false);
    }

    function getPosition() {
        return compRef.current.position;
    }

    function getChannel() {
        return compRef.current.channel;
    }

    const connectViewer = async (id, channel, credencials, origin, type, oldViewer = null) => {
        let params = {
            region: "eu-west-1",
            channelName: channel,
            clientId: `${origin}-${Date.now()}`,
            sendVideo: true,
            sendAudio: false,
            openDataChannel: false,
            widescreen: false,
            fullscreen: true,
            useTrickleICE: true,
            natTraversalDisabled: false,
            forceTURN: false,
            accessKeyId: credencials.accessKeyId,
            endpoint: null,
            secretAccessKey: credencials.secretAccessKey,
            sessionToken: credencials.sessionToken
        };
        let viewer = await startViewer(document.getElementById(id), params, () => { }, () => { }, remove, compRef.current.position, oldViewer, type);
        viewerRef.current = viewer;

        if (DEBUG) console.log("VIEWER PARAMS: ", params);
        if (DEBUG) console.log("COMPONENT VIEWER: ", viewer);
    }

    return (
        <div id={`${id}_component`} className={classes.root}
            // style={{
            //     width: small ? "220px" : width ? `${width}px` : '280px',
            //     height: small ? "220px" : height ? `${height}px` : '280px',
            //     position: transform ? 'absolute' : 'relative',
            //     top: transform ? transform.top : '0',
            //     left: transform ? transform.left : '0',
            //     marginRight: margin ? margin : 0,
            //     marginBottom: margin ? margin : 0,
            //     backgroundColor: debugChannel ? "rgba(255,255,255,0.4)" : 'none'
            // }}
            >
            <video
                ref={videoRef}
                id={id}
                // style={{
                //     width: small ? "220px" : width ? `${width}px` : '280px',
                //     height: small ? "220px" : height ? `${height}px` : '280px',
                // }}
                className={classes.video} autoPlay={true} muted>
            </video>
            {
                !started && small ?
                    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F2F3F4', width: '100%' }}>
                        <img src={Assets.Images.cycloid_grey_icon} style={{ width: "70px", height: "70px", opacity: 0.2 }} alt="" />
                    </div>
                    : null
            }
            {
            /*    remove && started ?
                    <div className={classes.actionRemove} onClick={onPressRemoveHandler}>
                        <Close style={{ color: "#FFFFFF" }} />
                    </div>
                    : null*/
            }
            {
                extend && started ?
                    <div className={classes.actionExtend} onClick={onPressExtendHandler}>
                        <Add style={{ color: "#B0191A" }} />
                    </div>
                    : null
            }

        </div>
    )
}

export default memo(ChannelFeed);