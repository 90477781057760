const Styles = theme => ({
    datesContent: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
    },
    dateInput:{
        display: 'block',
        marginRight: '0.8rem',
        width: '47%'
    },
    deleteBtn: {
        cursor: 'pointer', 
        padding: '5px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        borderRadius: '40px', 
        backgroundColor: '#064873'
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A'
    },
});

export default Styles;