import { DashboardRounded, PeopleRounded, ChildCareRounded, CastConnected, Settings, Schedule } from '@material-ui/icons';


const SideMenuStructer = [
    // {
    //     id: "81fa23b5-a653-448f-b939-30ee3d2525d2",
    //     label: "sidemenu-item-dashboard",
    //     icon: <DashboardRounded />,
    //     path: "/bo/dashboard",
    //     modules: []
    // },
    {
              
        id: "sidemenu-item-group",
        label: "sidemenu-item-videostream-channels",
        icon: <CastConnected />,
        path: "/bo/video-stream/channels",
        modules: []
            
    },
    {
        id: "sidemenu-item-users",
        label: "sidemenu-item-videostream-config",
        icon: <Schedule />,
        path: "/bo/video-stream/schedule",
        modules: []
            
    },
    {
        id: "cc8a9990-c781-4938-b5e3-8b3d1a37a65d",
        label: "sidemenu-item-config",
        icon: <Settings />,
        path: "/bo/settings",
        modules: [
            {
                id: "sidemenu-item-event",
                label: "sidemenu-item-config-event",
                icon: <CastConnected />,
                path: "/bo/settings/events",
            },
            {
                id: "sidemenu-item-other-params",
                label: "sidemenu-item-config-params",
                icon: <CastConnected />,
                path: "/bo/settings/other-params",
            },
            {
                id: "sidemenu-item-apps-config",
                label: "sidemenu-item-apps-config",
                icon: <CastConnected />,
                path: "/bo/apps-config",
            }
        ]
    },
    // {
    //     id: "cc8a9990-c781-4938-b5e3-8b3d1a37a65c",
    //     label: "sidemenu-item-form",
    //     icon: <ChildCareRounded />,
    //     path: "/bo/form",
    //     modules: []
    // }
]

export default SideMenuStructer;