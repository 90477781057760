import React, { createContext, useEffect, useState } from 'react';


import { GroupAndUserService } from './GroupAndUsers/group-and-user-service';
import { ThirdPartyService } from './ThirParty/thirdparty-service';
import { VideoStreamService } from './VideoStream/video-stream-service';


export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

    const [configurationApp, setConfigurationApp] = useState('CONFIG')
    const [eventDisplayName, setEventDisplayName] = useState('')

    const GroupAndUser = GroupAndUserService();
    const VideoStream = VideoStreamService();
    const ThirdParty = ThirdPartyService();

    const listConfigurationCloudfront = async() => {
        const result = await VideoStream.listConfigurationCloudfront()
        const eventDisplayName = result.filter( item => item.SK === "EventDisplayName")[0]
        setEventDisplayName(eventDisplayName.value);
        setConfigurationApp(result);
    }

    useEffect(() => {
        listConfigurationCloudfront();
    }, []);

    return (
        <ServiceContext.Provider value={{ GroupAndUser, VideoStream, configurationApp, eventDisplayName, ThirdParty }}>
            {props.children}
        </ServiceContext.Provider>
    )
}

export default ServiceContextProvider;