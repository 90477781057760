import React, { useContext } from 'react';

//Router
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { AuthContext } from '../context/auth/auth-context';

//Layout
import PrivateLayoutBo from '../layout/Bo/private-layout-bo';
import PrivateLayoutApp from '../layout/App/private-layout-app';

//Screens
import OAuthRedirector from '../screens/OAuthRedirector';

import ThirdParty from '../screens/Backoffice/ThirdParty'
import ThirdPartyForm from '../screens/Backoffice/ThirdParty/Form'
import DashboardBo from '../screens/Backoffice/Dashboard';

import VideoStreamChannelView from '../screens/Backoffice/VideoStream/Channels';
import VideoStreamConfigView from '../screens/Backoffice/VideoStream/Configuarations';


import SignIn from '../screens/Auth/SignIn';
import RecoverPassword from '../screens/Auth/RecoverPassword';
import NewPasswordRequired from '../screens/Auth/NewPasswordRequired';
import RecoverPasswordConfirm from '../screens/Auth/RecoverPasswordConfirm';

// VIDEO-WALL
import ViewerView from '../screens/VideoWall/Viewer';
import MasterView from '../screens/VideoWall/Master';
import MasterViewNextEvent from '../screens/VideoWall/Master/NextEvent';
import MasterViewCurrentEvent from '../screens/VideoWall/Master/CurrentEvent';
import ScheduleUpdateView from '../screens/Backoffice/VideoStream/Configuarations/Views/Update';

import LayoutVideoWallMaster from '../layout/VideowallMaster';


import { VideowallProvider } from '../context/Videowall';
import Events from '../screens/Backoffice/Settings/Events';
import OtherParameters from '../screens/Backoffice/Settings/OtherParameters';
import { ServiceContext } from '../context/services/service-context';
import { PhotoGallery } from '../screens/PhotoGallery';
import { Gallery } from '../screens/PhotoGallery/gallery';
import { Photo } from '../screens/PhotoGallery/photo';


const AllRoutes = () => {

    const { isLoading, signin } = useContext(AuthContext)

    const { configurationApp, eventDisplayName } = useContext(ServiceContext)

    const location = useLocation()


    if (isLoading) return null

    return (
        <Routes>

            <Route path="/">

                <Route path='/' element={
                    <VideowallProvider>
                        <LayoutVideoWallMaster eventName={eventDisplayName}>
                            <MasterViewCurrentEvent eventConfig={configurationApp}/>
                        </LayoutVideoWallMaster>
                    </VideowallProvider>
                } />

                <Route path='/next-event' element={
                    <VideowallProvider>
                        <LayoutVideoWallMaster eventName={eventDisplayName}>
                            <MasterViewNextEvent eventConfig={configurationApp} />
                        </LayoutVideoWallMaster>
                    </VideowallProvider>
                } />





                {/* <Route path='*' element={<Navigate to="/next-event" />} /> */}
            </Route>

            <Route path='photo-gallery'>
                <Route path='/photo-gallery' element={<PhotoGallery />} />
                <Route path='/photo-gallery/:id' element={<Gallery />} />
                <Route path='/photo-gallery/photo' element={<Photo />} />
            </Route>



            <Route path="videowall">
                <Route path="/videowall" element={
                        <ViewerView />
                    // <RequireAuthVideoWall location={location}>
                    // </RequireAuthVideoWall>
                } />
                <Route path="auth/sign-in" element={<SignIn />} />
                <Route path="auth/sign-in/secret" element={<SignIn />} />
                <Route path="auth/recover-password" element={<RecoverPassword />} />
                <Route path="auth/recover-password/confirm" element={<RecoverPasswordConfirm />} />
                <Route path="auth/new-password-required" element={<NewPasswordRequired />} />
                <Route path='*' element={<Navigate to="videowall" />} />
            </Route>

            <Route path="bo">
                <Route path='/bo' element={<Navigate to="/bo/video-stream/channels" />} />
                <Route path="video-stream" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <DashboardBo />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="video-stream/channels" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <VideoStreamChannelView />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="video-stream/schedule" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <VideoStreamConfigView />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="video-stream/schedule/:id" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <ScheduleUpdateView />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="settings" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <DashboardBo />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />


                <Route path="settings/events" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Events />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />
                <Route path="settings/other-params" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <OtherParameters />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="apps-config" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <ThirdParty />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />
                <Route path="apps-config/detail" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <ThirdPartyForm />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                {/*<Route path="groups" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Groups />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="groups/create" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <CreateGroup />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="groups/edit/:slug" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <EditGroup />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />


                <Route path="users" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Users />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="users/create" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <CreateUser />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="users/edit/:slug" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <EditUser />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="todos/*" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Todos />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="form/*" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Form />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } /> */}


                <Route path='*' element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <p>PAGE BO NO FOUND</p>
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="auth/sign-in" element={<SignIn />} />
                <Route path="auth/recover-password" element={<RecoverPassword />} />
                <Route path="auth/recover-password/confirm" element={<RecoverPasswordConfirm />} />
                <Route path="auth/new-password-required" element={<NewPasswordRequired />} />
                <Route path='*' element={<p>PAGE BO NO FOUND</p>} />
            </Route>

            <Route path="oauth-redirector" element={<OAuthRedirector />} />

            <Route path='*' element={<p>NO FOUND</p>} />
        </Routes>
    )


}


const RequireAuthBo = ({ children }) => {


    const { isAuthenticated, groups, roleBo } = useContext(AuthContext)

    const isIncluded = roleBo?.some(value => groups?.includes(value)) || roleBo?.includes('*')

    if (!isAuthenticated || !isIncluded) {
        return <Navigate to="/bo/auth/sign-in" state={{ from: '/bo/video-stream/channels' }} replace />;
    }
    return children;
}

const RequireAuthVideoWall = ({ children, location,  }) => {
    const { isAuthenticated, groups, roleBo } = useContext(AuthContext)
    // const isIncluded = roleBo?.some(value => groups?.includes(value)) || roleBo?.includes('*')
    const secretId = location.search.split('secretId=')[1];

    if(!!secretId && !isAuthenticated){
        return <Navigate to="/videowall/auth/sign-in/secret" state={{ from: '/videowall', secretId: secretId }} replace />;
    }

    if (!isAuthenticated && !secretId /*|| !isIncluded*/) {
        return <Navigate to="/videowall/auth/sign-in" state={{ from: '/videowall' }} replace />;
    }
    return children;
}


const RequireAuthApp = ({ children }) => {

    const { groups, roleApp, signout, isAuthenticated } = useContext(AuthContext)

    const isIncluded = roleApp?.some(value => groups?.includes(value)) || roleApp?.includes('*')

    if (!isIncluded && isAuthenticated) {
        signout()
    }
    return children;
}




export default AllRoutes;
