import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Styles from './style';
import { Add } from '@material-ui/icons'
import { useEffect } from 'react';
import DateItem from './components/DateItem';

const useStyles = makeStyles(Styles);

const DateList = ({onDateChangeHandler, removeNewDateScheduleHandler, addNewDateScheduleHandler, rows, isDidabled}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [dateRows, setDateRows] = useState([]);

    const renderDateRows = () => {
        let newRenderRows = [];
        if(rows.renders){
            rows.renders.forEach((row, index) => {
                newRenderRows.push(
                    <DateItem key={row.id} firstItem={index} item={row} onDateChangeHandler={onDateChangeHandler} removeNewDateScheduleHandler={removeNewDateScheduleHandler} isDidabled={isDidabled}/>
                )
            })
        }
        setDateRows(newRenderRows);
    };

    useEffect( () => {
        renderDateRows()
    }, [rows, isDidabled])

  return (
    <div className={classes.root}>
        <div className={classes.content}>
            {dateRows && dateRows.length > 0 && dateRows}
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {isDidabled ? null : <div onClick={() => addNewDateScheduleHandler()} style={{ cursor: 'pointer', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', backgroundColor: '#064873', }}>
                    <Add style={{ color: '#ffffff', fontSize: "19px" }} />
                </div>}
            </div>
        </div>
    </div>
  )
}

export default DateList