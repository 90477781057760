const Styles = theme => ({
    contentInput: {
        display: 'block',
        marginBottom: '1rem',
    },
    labelFileUpload:{
        cursor: 'pointer',
        display: 'block',
        width: '100%',
        
    },
    labelTitle: {
        fontSize: '0.75rem',
        marginLeft: '14px',
        marginBottom: '0.5rem',
        opacity: '0.75'
    },
    imageContent: {
        display: 'block',
        backgroundColor: '#dfdfdf',
        padding: '10px',
        position: 'relative',
        borderRadius: '10px',
        boxShadow: 'inset 0px 0px 16px 0px rgb(0 0 0 / 10%)',
        overflow: 'hidden',
        
        '& img': {
            display: 'block',
            objectFit: 'contain',
            maxHeight: '100%',
            maxWidth: '100%',
        },
        '& span': {
            backgroundColor: 'rgba(0,0,0,0.7)',
            position: 'absolute',
            top: '0px',
            left: '0px',
            height: '100%',
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            fontSize: '0.85rem',
            color: 'rgba(255,255,255)',
            transition: '0.2s 0.3s linear',
            opacity: 0,
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover span': {
            transition: '0.2s 0.3s linear',
            opacity: 1,
        },
    },
    aspectRatio:{
        fontSize: '0.7rem',
        color: '#cecece',
        display: 'block',
    },
    inputHidden: {
        display: 'block',
        opacity: 0,
        height: 0,
        width: 0,
    },
});

export default Styles