const Styles = theme => ({
    root: {
        width: '80%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
    },
    paper:{
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        margin: "40px 0px",
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    contentInput:{
        display: 'block',
        marginBottom: '1rem',
        width: '100%'
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A'
    },
})

export default Styles;