import { API, graphqlOperation } from "aws-amplify";
import { listBOModules, listUser, listGroup, getUser } from '../../graphql/queries';
import { createGroup, createUser, updateUser, removeUserFromGroup } from "../../graphql/mutations";

export const listModules = async () => {
    return await API.graphql(graphqlOperation(listBOModules));
}

export const CreateGroup = async (data) => {
    return await API.graphql(graphqlOperation(createGroup, data));
};

export const ListUsers = async () => {
    return await API.graphql(graphqlOperation(listUser));
};

export const ListGroups = async () => {
    return await API.graphql(graphqlOperation(listGroup));
};

export const CreateUser = async (data) => {
    return await API.graphql(graphqlOperation(createUser, data));
};

export const UpdateUser = async (data) => {
    return await API.graphql(graphqlOperation(updateUser, data));
};

export const GetUser = async (data) => {
    return await API.graphql(graphqlOperation(getUser, data));
};

export const RemoveUserFromGroup = async (data) => {
    return await API.graphql(graphqlOperation(removeUserFromGroup, data));
};

