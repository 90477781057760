import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { makeStyles, Dialog, Slide, } from '@material-ui/core';
import BackIcon from '@material-ui/icons/NavigateBefore';
import { ServiceContext } from '../../../context/services/service-context';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const Schedule = ({
    onBack
}) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { VideoStream } = useContext(ServiceContext);
    const [schedules, setSchedules] = useState([])



    useEffect(() => {
        const fetchEventSchedule = async () => {

            const _schedules = await VideoStream.listEventSchedulesCloudfront()
           
            let dates = [];
            _schedules.forEach(it => {
                dates = [...dates, ...it.dates]
            });
            const schedulesSorted = dates.sort((a, b) => a.startTimestamp - b.startTimestamp);
            
            let dataObject = {}

            schedulesSorted.map(it => {
                const date = new Date(it.startTimestamp)
                const key = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`
                if (dataObject[key]) {
                    dataObject[key] = [...dataObject[key], it.startTimestamp]
                } else {
                    dataObject[key] = [it.startTimestamp]
                }
            })

            const objectValues = Object.values(dataObject);

            

            setSchedules(objectValues)
        }
        fetchEventSchedule()
    }, [])




    return (
        <Dialog fullScreen open={true} TransitionComponent={Transition}>
            <div className={classes.root}>
                <div className={classes.header} onClick={() => onBack()}>
                    <BackIcon />
                    <span>{t('back')}</span>
                </div>
                <div className={classes.content}>
                    <h5>{t('videowall-app-schedule-title')}</h5>
                    <p>{t('videowall-app-schedule-info')}</p>
                    <div className={classes.boxDate}>
                        {schedules.map((it, index) => {
                            const times = it
                            const day = it[0]
                            return (
                                <div key={`event-${index}`} className={classes.eventRow}
                                    style={{ borderBottom: (index === schedules?.length - 1) ? 'none' : '1px solid #0A3655' }}>
                                    <h3>{moment(day).format('dddd   DD/MM')}</h3>
                                    <div className={classes.timesRow}>
                                        {
                                            times.map(dt => (
                                                <span key={dt}> {moment(dt).format('HH:mm')}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Schedule;


const useStyles = makeStyles(theme => ({
    root: {
       backgroundColor: 'rgba(0, 0, 0, 0.7)',
        height: '100%',
        padding: '18px',
    },
     header: {
        display: 'flex',
        alignItems: 'center',
        zindex: 10,
         '& span': {
             cursor: 'pointer',
            font: 'normal normal normal 16px/18px OpenSans'
        }
    },
    content: {
       zindex: 10,
       margin: '18px 16px 24px',
        '& h5': {
            font: 'normal normal normal 32px/37px OpenSans',
            margin: '0 0 24px',
        },
        '& p': {
            font: 'normal normal normal 14px/18px OpenSans',
            margin: 0
        }
    },
    boxDate: {
        marginTop: "24px",
    },
    eventRow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '16px',
        '& h3': {
            font: 'normal normal bold 24px/26px OpenSans',
            margin: 0,
            color: '#0A3655'
        }
    },
    timesRow: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            font: 'normal normal normal 24px/26px OpenSans',
            margin: '0 0 13px',
            color: '#FFF'
        },
        '& span:not(:last-child):after': {
            content: '","',
            marginRight: '6px'
        }
    }
}))
