import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Styles from './style';
import { Button, CircularProgress, MenuItem, Paper, Switch, TextField } from '@material-ui/core';
import { GridOn, TextFields } from '@material-ui/icons';
import { ServiceContext } from '../../../../context/services/service-context';
import ImageUpdate from '../../../../components/Backoffice/ImageUpdate';

const useStyles = makeStyles(Styles);

const OtherParameters = () => {
  const { t } = useTranslation();
  const { VideoStream } = useContext(ServiceContext);
  const classes = useStyles();

  const [application, setApplication] = useState({
    CurrentEventName: '',
    ChannelThreshold: '',
    MaxDisplayInterval: '',
    MinDisplayInterval: '',
    MediaGridPositions: '',
    QueueContentCount: '',
    BurnChannel: false,
  })
  const [editDisabled, setEditDisabled] = useState(true);

  const [isLoading, setIsLoading] = useState(false)

  const [qrImageFile, setQrImageFile] = useState('');
  const [apectRatioImage, setApectRatioImage] = useState(false);
  const [footerImageFile, setFooterImageFile] = useState('');
  const [apectRatioFooterImage, setApectRatioFooterImage] = useState(false);

  const listEventConfig = async () => {
    const result = await VideoStream.listConfigurations();
    setApplicationConfigState(result);
  }

  const setApplicationConfigState = (result) => {
    result.forEach((item) => {
      if(item.PK === "SERVER"){
        if(item.SK === "BurnChannel"){
          setApplication(prev => ({...prev, [item.SK]: (item.value === "true")}));
        } else if(item.SK === "MediaGridPositions" ){
          const jsonParsePositions = JSON.parse(item.value);
          console.log(jsonParsePositions)
          setApplication(prev => ({...prev, [item.SK]: jsonParsePositions.positions.length}));
        } else{
          setApplication(prev => ({...prev, [item.SK]: item.value}));
        }
      }
      if(item.SK === "QueueContentCount"){
        setApplication(prev => ({...prev, [item.SK]: item.value}));
      }
    })
  }

  useEffect(() => listEventConfig(), []);

  const inputChangeHandler = (event) => {
    setApplication(prev => ({...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const updateOtherParams = async() => {
    setIsLoading(true);
    try {
      await VideoStream.updateConfiguration({PK: "SERVER", SK: "CurrentEventName", value: application.CurrentEventName})
      await VideoStream.updateConfiguration({PK: "SERVER", SK: "BurnChannel", value: application.BurnChannel})
      await VideoStream.updateConfiguration({PK: "SERVER", SK: "ChannelThreshold", value: application.ChannelThreshold})
      await VideoStream.updateConfiguration({PK: "SERVER", SK: "MaxDisplayInterval", value: application.MaxDisplayInterval})
      await VideoStream.updateConfiguration({PK: "SERVER", SK: "MinDisplayInterval", value: application.MinDisplayInterval})
      await VideoStream.updateConfiguration({PK: "APPLICATION", SK: "QueueContentCount", value: application.QueueContentCount})
      let eventPositions = {positions: []};
      for (let i = 0; i < application.MediaGridPositions; i++) {
        eventPositions.positions.push('position_'+i);
      }
      await VideoStream.updateConfiguration({PK: "SERVER", SK: "MediaGridPositions", value: JSON.stringify(eventPositions)})
      if(apectRatioImage){
        const urlSigned = await VideoStream.getS3SignedUrl(qrImageFile.fileName)
        await VideoStream.updateEventImageDisplay(urlSigned.signedUrl, qrImageFile)
      }
      if(apectRatioFooterImage){
        const urlSigned = await VideoStream.getS3SignedUrl(footerImageFile.fileName)
        await VideoStream.updateEventImageDisplay(urlSigned.signedUrl, footerImageFile)
      }
    }catch(e){console.log(e)}
    finally{
      setIsLoading(false)
      setEditDisabled(true)
    }
  };

  const onChangeQrImage = async (fileImage, aspect) => {
    if(fileImage && aspect){
      setApectRatioImage(aspect);
      setQrImageFile(fileImage)
    }else{
      setApectRatioImage(aspect);
    }
  }
  const onChangeFooterImage = async (fileImage, aspect) => {
    if(fileImage && aspect){
      setApectRatioFooterImage(aspect);
      setFooterImageFile(fileImage)
    }else{
      setApectRatioFooterImage(aspect);
    }
  }
    

  return (
    <div className={classes.root}>
      <div className={classes.title}>{t('bo-other-params-title')}</div>
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.contentInput}>
          <TextField
            name='CurrentEventName' 
            label={t('bo-event-name')} 
            value={application.CurrentEventName} 
            placeholder={t('bo-event-name')} 
            variant="outlined" id="event-displey-name" 
            InputProps={{ startAdornment: <TextFields className={classes.icons} />  }}
            onChange={event => inputChangeHandler(event)}
            disabled={editDisabled}
          />
        </div>
        <div className={classes.contentInput}>
          <TextField
            name='ChannelThreshold'
            label={t('bo-event-channelThreshold')} 
            value={application.ChannelThreshold} 
            placeholder={t('bo-event-channelThreshold')} 
            variant="outlined" id="event-displey-name" 
            InputProps={{ startAdornment: <TextFields className={classes.icons} />  }}
            onChange={event => inputChangeHandler(event)}
            disabled={editDisabled}
          />
        </div>
        <div className={classes.contentInput}>
          
          <TextField
            name='MinDisplayInterval' 
            label={t('bo-event-interval-min')} 
            value={application.MinDisplayInterval} 
            placeholder={t('bo-event-interval-min')} 
            variant="outlined" id="event-displey-name" 
            InputProps={{ startAdornment: <TextFields className={classes.icons} />  }}
            onChange={event => inputChangeHandler(event)}
            disabled={editDisabled}
          />
          
          <TextField 
            name='MaxDisplayInterval'
            label={t('bo-event-interval-max')} 
            value={application.MaxDisplayInterval} 
            placeholder={t('bo-event-interval-max')} 
            variant="outlined" id="event-displey-name" 
            InputProps={{ startAdornment: <TextFields className={classes.icons} />  }}
            onChange={event => inputChangeHandler(event)}
            disabled={editDisabled}
          />
        </div>
        <div className={classes.contentInput}>
          <TextField 
            name='MediaGridPositions'
            select
            label={t('bo-event-grid-position')} 
            value={application.MediaGridPositions} 
            placeholder={t('bo-event-grid-position')} 
            variant="outlined" id="event-displey-name" 
            InputProps={{ startAdornment: <GridOn className={classes.icons} />  }}
            onChange={event => inputChangeHandler(event)}
            disabled={editDisabled}
          >
            <MenuItem key={1} value={1}>1</MenuItem>
            <MenuItem key={2} value={2}>2</MenuItem>
            <MenuItem key={3} value={3}>3</MenuItem>
            <MenuItem key={4} value={4}>4</MenuItem>
            <MenuItem key={5} value={5}>5</MenuItem>
            <MenuItem key={6} value={6}>6</MenuItem>
            <MenuItem key={7} value={7}>7</MenuItem>
          </TextField>
        </div>
        <div className={classes.contentInput}>
          <TextField
            name='QueueContentCount' 
            label={t('bo-event-queue-size')} 
            value={application.QueueContentCount} 
            placeholder={t('bo-event-queue-size')} 
            variant="outlined" id="event-displey-name" 
            InputProps={{ startAdornment: <TextFields className={classes.icons} />  }}
            onChange={event => inputChangeHandler(event)}
            disabled={editDisabled}
          />
        </div>
        <div className={classes.contentInput}>
          <div>{t('bo-event-burnchannel')}</div>
          <div>
            <span>{!application.BurnChannel ? 'ON' : 'OFF'}</span>
            <Switch 
              disabled={editDisabled}
              checked={!application.BurnChannel} 
              onChange={event => setApplication(prev => ({...prev, BurnChannel: event.target.checked}))} 
              color="primary" />

          </div>
        </div>
        <ImageUpdate onChangeImage={onChangeQrImage} disabled={editDisabled} fileName="qrCode" height="auto" width="250px" aspectRatio={1} aspectRatioText={t('bo-settings-qr-warning-sizes')} label={t('bo-settings-qr-label-sizes')} />
        <ImageUpdate onChangeImage={onChangeFooterImage} disabled={editDisabled} fileName="logoFooter" height="auto" width="50%" aspectRatio={13} aspectRatioText={t('bo-settings-footer-warning-sizes')} label={t('bo-settings-footer-label-sizes')} />
        {editDisabled ? 
          <Button type='button' variant="contained" classes={{ root: classes.button }} onClick={() => setEditDisabled(false)}>{t('bo-event-edit-btn-label')}</Button>
          :
        <Button type='button' variant="contained" classes={{ root: classes.button }} onClick={() => updateOtherParams()}>{
            isLoading ? <CircularProgress size={24} className={classes.progress} /> : 
            t('bo-event-create-btn-label')
        }</Button>}

      </Paper>
    </div>
  )
}

export default OtherParameters