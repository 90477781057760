/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const givePoints = /* GraphQL */ `
  mutation GivePoints($input: GivePointsInput) {
    givePoints(input: $input) {
      statusCode
      message
      __typename
    }
  }
`;
export const createCustomEventNotification = /* GraphQL */ `
  mutation CreateCustomEventNotification(
    $input: CreateCustomEventNotificationInput
  ) {
    createCustomEventNotification(input: $input) {
      statusCode
      message
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    createUser(input: $input) {
      sub
      username
      enabled
      status
      createAt
      given_name
      family_name
      name
      picture
      email
      groups
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput) {
    createGroup(input: $input) {
      groupName
      description
      userGroupId
      __typename
    }
  }
`;
export const deleteCustomEventNotification = /* GraphQL */ `
  mutation DeleteCustomEventNotification(
    $input: DeleteCustomEventNotificationInput
  ) {
    deleteCustomEventNotification(input: $input) {
      statusCode
      message
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      statusCode
      message
      __typename
    }
  }
`;
export const removeUserFromGroup = /* GraphQL */ `
  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput) {
    removeUserFromGroup(input: $input) {
      statusCode
      message
      __typename
    }
  }
`;
export const updateChannelStatus = /* GraphQL */ `
  mutation UpdateChannelStatus($input: UpdateChannelInput) {
    updateChannelStatus(input: $input) {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const updateCustomEventNotification = /* GraphQL */ `
  mutation UpdateCustomEventNotification(
    $input: UpdateCustomEventNotificationInput
  ) {
    updateCustomEventNotification(input: $input) {
      statusCode
      message
      __typename
    }
  }
`;
export const removeContentFromGrid = /* GraphQL */ `
  mutation RemoveContentFromGrid($input: RemoveContentFromGridInput) {
    removeContentFromGrid(input: $input) {
      errorMessage
      errorCode
      name
      position
      __typename
    }
  }
`;
export const extendContentFromGrid = /* GraphQL */ `
  mutation ExtendContentFromGrid($input: ExtendContentFromGridInput) {
    extendContentFromGrid(input: $input) {
      errorMessage
      errorCode
      name
      position
      __typename
    }
  }
`;
export const startEvent = /* GraphQL */ `
  mutation StartEvent {
    startEvent {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const stopEvent = /* GraphQL */ `
  mutation StopEvent {
    stopEvent {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const syncSignalingChannels = /* GraphQL */ `
  mutation SyncSignalingChannels {
    syncSignalingChannels {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const reportEnteredApp = /* GraphQL */ `
  mutation ReportEnteredApp($input: ReportEnteredAppInput) {
    reportEnteredApp(input: $input) {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const reportQRCodeUsed = /* GraphQL */ `
  mutation ReportQRCodeUsed {
    reportQRCodeUsed {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const startStreamingEvent = /* GraphQL */ `
  mutation StartStreamingEvent {
    startStreamingEvent {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const resetAllChannels = /* GraphQL */ `
  mutation ResetAllChannels {
    resetAllChannels {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const writePositionContents = /* GraphQL */ `
  mutation WritePositionContents($input: WritePositionContentsInput) {
    writePositionContents(input: $input) {
      errorMessage
      errorCode
      __typename
    }
  }
`;
export const removeFromQueue = /* GraphQL */ `
  mutation RemoveFromQueue($input: RemoveFromQueueInput) {
    removeFromQueue(input: $input) {
      errorMessage
      errorCode
      name
      __typename
    }
  }
`;
export const createBOModule = /* GraphQL */ `
  mutation CreateBOModule(
    $input: CreateBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    createBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBOModule = /* GraphQL */ `
  mutation UpdateBOModule(
    $input: UpdateBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    updateBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBOModule = /* GraphQL */ `
  mutation DeleteBOModule(
    $input: DeleteBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    deleteBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventNotificationSecret = /* GraphQL */ `
  mutation CreateEventNotificationSecret(
    $input: CreateEventNotificationSecretInput!
    $condition: ModelEventNotificationSecretConditionInput
  ) {
    createEventNotificationSecret(input: $input, condition: $condition) {
      id
      used
      ttl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventNotificationSecret = /* GraphQL */ `
  mutation UpdateEventNotificationSecret(
    $input: UpdateEventNotificationSecretInput!
    $condition: ModelEventNotificationSecretConditionInput
  ) {
    updateEventNotificationSecret(input: $input, condition: $condition) {
      id
      used
      ttl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventNotificationSecret = /* GraphQL */ `
  mutation DeleteEventNotificationSecret(
    $input: DeleteEventNotificationSecretInput!
    $condition: ModelEventNotificationSecretConditionInput
  ) {
    deleteEventNotificationSecret(input: $input, condition: $condition) {
      id
      used
      ttl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventNotification = /* GraphQL */ `
  mutation CreateEventNotification(
    $input: CreateEventNotificationInput!
    $condition: ModelEventNotificationConditionInput
  ) {
    createEventNotification(input: $input, condition: $condition) {
      project
      id
      eventType
      username
      email
      secretARN
      cognitoUserPoolID
      cognitoAppClientID
      kmsKeyARN
      apiURL
      videowallUsername
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventNotification = /* GraphQL */ `
  mutation UpdateEventNotification(
    $input: UpdateEventNotificationInput!
    $condition: ModelEventNotificationConditionInput
  ) {
    updateEventNotification(input: $input, condition: $condition) {
      project
      id
      eventType
      username
      email
      secretARN
      cognitoUserPoolID
      cognitoAppClientID
      kmsKeyARN
      apiURL
      videowallUsername
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventNotification = /* GraphQL */ `
  mutation DeleteEventNotification(
    $input: DeleteEventNotificationInput!
    $condition: ModelEventNotificationConditionInput
  ) {
    deleteEventNotification(input: $input, condition: $condition) {
      project
      id
      eventType
      username
      email
      secretARN
      cognitoUserPoolID
      cognitoAppClientID
      kmsKeyARN
      apiURL
      videowallUsername
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMediaGridContent = /* GraphQL */ `
  mutation CreateMediaGridContent(
    $input: CreateMediaGridContentInput!
    $condition: ModelMediaGridContentConditionInput
  ) {
    createMediaGridContent(input: $input, condition: $condition) {
      PK
      SK
      status
      startDisplayTimestamp
      endDisplayTimestamp
      contentRef
      arn
      name
      disconnectTimestamp
      connectTimestamp
      updatedAt
      count
      creationTimestamp
      appearedTimestamp
      createdAt
      __typename
    }
  }
`;
export const updateMediaGridContent = /* GraphQL */ `
  mutation UpdateMediaGridContent(
    $input: UpdateMediaGridContentInput!
    $condition: ModelMediaGridContentConditionInput
  ) {
    updateMediaGridContent(input: $input, condition: $condition) {
      PK
      SK
      status
      startDisplayTimestamp
      endDisplayTimestamp
      contentRef
      arn
      name
      disconnectTimestamp
      connectTimestamp
      updatedAt
      count
      creationTimestamp
      appearedTimestamp
      createdAt
      __typename
    }
  }
`;
export const deleteMediaGridContent = /* GraphQL */ `
  mutation DeleteMediaGridContent(
    $input: DeleteMediaGridContentInput!
    $condition: ModelMediaGridContentConditionInput
  ) {
    deleteMediaGridContent(input: $input, condition: $condition) {
      PK
      SK
      status
      startDisplayTimestamp
      endDisplayTimestamp
      contentRef
      arn
      name
      disconnectTimestamp
      connectTimestamp
      updatedAt
      count
      creationTimestamp
      appearedTimestamp
      createdAt
      __typename
    }
  }
`;
export const createEventSchedule = /* GraphQL */ `
  mutation CreateEventSchedule(
    $input: CreateEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    createEventSchedule(input: $input, condition: $condition) {
      id
      name
      description
      startTimestamp
      endTimestamp
      dates {
        startTimestamp
        endTimestamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventSchedule = /* GraphQL */ `
  mutation UpdateEventSchedule(
    $input: UpdateEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    updateEventSchedule(input: $input, condition: $condition) {
      id
      name
      description
      startTimestamp
      endTimestamp
      dates {
        startTimestamp
        endTimestamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventSchedule = /* GraphQL */ `
  mutation DeleteEventSchedule(
    $input: DeleteEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    deleteEventSchedule(input: $input, condition: $condition) {
      id
      name
      description
      startTimestamp
      endTimestamp
      dates {
        startTimestamp
        endTimestamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      PK
      SK
      timestamp
      description
      reference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      PK
      SK
      timestamp
      description
      reference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      PK
      SK
      timestamp
      description
      reference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConfiguration = /* GraphQL */ `
  mutation CreateConfiguration(
    $input: CreateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    createConfiguration(input: $input, condition: $condition) {
      PK
      SK
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConfiguration = /* GraphQL */ `
  mutation UpdateConfiguration(
    $input: UpdateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    updateConfiguration(input: $input, condition: $condition) {
      PK
      SK
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConfiguration = /* GraphQL */ `
  mutation DeleteConfiguration(
    $input: DeleteConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    deleteConfiguration(input: $input, condition: $condition) {
      PK
      SK
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
