import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../components/Shared/Loading';
import Faqs from '../../components/VideoWall/Faqs';
import LanguageOptions from '../../components/VideoWall/LanguageOptions';
import Schedule from '../../components/VideoWall/Schedule';
import TermsAndConditions from '../../components/VideoWall/TermsAndConditions';
import { useVideowall } from '../../context/Videowall';
import { AuthContext } from '../../context/auth/auth-context';
import { ServiceContext } from '../../context/services/service-context';
import Styles from './style';

import Assets from '../../assets';


const useStyles = makeStyles(Styles);


const LayoutVideoWallMaster = (props) => {

    const classes = useStyles();

    let [searchParams, setSearchParams] = useSearchParams();

    const { VideoStream } = useContext(ServiceContext);
    const { currentUserSession } = useContext(AuthContext);

    // const [eventDisplayName, setEventDisplayName] = useState('');


    const {
        videowallLoading,
        termsAccepted,
        onAcceptTerms,
        onBackTerms,
        language,
        onSelectLang,
        showFaqs,
        setShowFaqs,
        showSchedule,
        setShowSchedule,
        showTerms,
        setShowTerms
    } = useVideowall()


    const sendReport = async (data) => {
        console.log('Send Report')
        const session = await currentUserSession()
        let type = 'AMAZON_COGNITO_USER_POOLS'
        if (!session) type = 'AWS_IAM'

        await VideoStream.reportEnteredApp(data, type)
    }


    useEffect(() => {
        let qrcodeParam = searchParams.get("qrcode");
        if (qrcodeParam) {
            const data = { qrCode: true}
            sendReport(data)
        } else {
            const data = { qrCode: false }
            sendReport(data)
        }
        // getEventDisplayName();

        window.addEventListener("load",function() {
            setTimeout(() => {
                window.scrollTo(0, 1);
            }, 0);
        });
    }, [])

    // const getEventDisplayName = async() => {
    //     const result = await VideoStream.getEventDisplayName();
    //     setEventDisplayName(result)
    // }


    if (videowallLoading) return <Loading />

    if (!language) return <LanguageOptions onSelect={(lang) => onSelectLang(lang)} props={props} />

    if (!termsAccepted) return <TermsAndConditions hiddenButtons={false} onAccept={(value) => onAcceptTerms(value)} onBack={() => onBackTerms()} />

    return (
        <React.Fragment >
            <div className={classes.root}>
            <div className={classes.bgDot}>
                <div className={classes.header}>
                    {/* <div className={classes.top}>
                        <img src={'https://d2mqi67xdf5186.cloudfront.net/logo?'+Date().now} alt="" width={78} height={48}/>
                    </div> */}
                    <div className={classes.titleBox}>
                        <div>{props.eventName}</div>
                    </div>
                </div>
                {props.children}
            </div>
            {showFaqs && <Faqs onBack={() => setShowFaqs(false)} />}
            {showSchedule && <Schedule onBack={() => setShowSchedule(false)} />}
            {showTerms && <TermsAndConditions hiddenButtons={true} onAccept={(value) => onAcceptTerms(value)} onClose={() => setShowTerms()} />}
            </div>
        </React.Fragment>
    );
}

export default LayoutVideoWallMaster;
