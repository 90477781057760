import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Styles } from './style';
import DownloadIcon from '../../assets/images/receive-square.png'
import { useTranslation } from 'react-i18next';
import { Storage } from 'aws-amplify';

const useStyles = makeStyles(Styles);


export const Photo = () => {
    const classes = useStyles();
    const { state } = useLocation();
    const [photosShow, setPhotosShow] = useState()
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        setPhotosShow(state.photo);
        console.log(state.photo)
    }, [state])

    const downloadBlob = async(blob, filename) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename || 'download';
      const clickHandler = () => {
        console.log(url, blob)
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
      return a;
    };

    async function download() {
      const result = await Storage.get(state.nameKey, { download: true });
      downloadBlob(result.Body, state.nameKey.split('/').pop());
    }


  return (
    <div className={classes.root}>
            <div className={classes.imageBigContainer}>
                <img className={classes.imageBigSize} src={photosShow} alt={'name'} />
            </div>
            <div className={classes.buttonBottomContainer}>
            <div className={classes.backBottomButton} onClick={() => navigate(-1)}>{t('bo-action-btn-label-back')}</div>
            {/* <div className={classes.downloadBottomButton} onClick={() => download(photosShow)}>{t('photo-download')} <img src={DownloadIcon} alt='dow' /></div> */}
            <a className={classes.downloadBottomButton} href={'image://'+photosShow} target='_blank' rel="noreferrer">{t('photo-download')} <img src={DownloadIcon} alt='dow' /></a>
            </div>
    </div>
  )
}
